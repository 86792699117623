import React, { useState } from "react";
import CustomBackHeader from "../../shared/components/CustomBackHeader";
import Countdown from "react-countdown";
import Faq from "react-faq-component";

import { rupiahFormat } from "../../../app/helper";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import copyImage from "../../../img/profile/copyImage.svg";
import { useSelector } from "react-redux";
import bcaLogo from "../../../img/payment/bcaLogo.png";
import { useEffect } from "react";
// import dataBank from "../../../app/dataBank";
import CustomButton from "../../shared/components/CustomButton";
import axios from "axios";

export default function PendingPayment({ handleGetOrderDetail }) {
  const { storeName } = useParams();
  const navigate = useNavigate();
  const { loading, orderDetail } = useSelector((state) => state.cart);

  const handleCopy = () => {
    navigator.clipboard.writeText(orderDetail?.vaNumber);
    message.success("Berhasil disalin");
  };

  const [dataBank, setDataBank] = useState([]);

  const handleGetVirtualAccount = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/method/alfamind.json.banks`)
      .then(async (res) => {
        await setDataBank(res.data.message);
      })
      .catch((error) => {
        alert("Error : list bank not found!");
      });
  };

  useEffect(() => {
    handleGetVirtualAccount();
  }, []);

  const [ChoosedBank, setChoosedBank] = useState({});

  useEffect(() => {
    if (orderDetail !== null) {
      const filteredBank = dataBank.find(
        (f) => f?.bankCode === orderDetail.paymentCode,
      );
      setChoosedBank(filteredBank);
    }
  }, [orderDetail, dataBank]);

  const handleBelanjaLagi = () => navigate(`/${storeName}`);

  const handleCekStatus = () => {
    handleGetOrderDetail();
  };

  return (
    <>
      <div style={{ background: "#FDF8F8" }}>
        <CustomBackHeader title="Informasi Pembayaran" href={`/${storeName}`} />

        <div className=" mt-5 pt-4">
          {/* <pre>{JSON.stringify(dataBank, null, 2)}</pre> */}
          <div className="d-flex align-items-center justify-content-between mb-4 p-4 bg-white">
            <p className="mb-0 text-bold">Total Pembayaran</p>
            <p className="mb-0 text-bold text-red ">
              {rupiahFormat(
                orderDetail?.totalAmountFinal + orderDetail?.totalMarginSOPromo,
              )}
            </p>
          </div>
          <div className="p-4 bg-white">
            <div className="d-flex align-items-center mb-3 ">
              <img
                src={ChoosedBank?.data?.image.replaceAll('"', "")}
                alt=""
                className="me-2 p-1"
                style={{
                  // aspectRatio: "2/1",
                  height: 30,
                  objectFit: "contain",
                  width: 60,
                  border: "0.75px solid #EDEDED",
                  borderRadius: 3,
                }}
              />
              <p className="mb-0 text-bold" style={{ fontSize: 16 }}>
                {orderDetail?.paymentDescriptionIn}
              </p>
            </div>
            <p className="mb-0 weight-light">Nomor Virtual Account</p>

            <div className="d-flex align-items-center justify-content-between mb-3  bg-white">
              <p className="mb-0 text-bold text-red" style={{ fontSize: 18 }}>
                {orderDetail?.vaNumber}
              </p>
              <p
                className="mb-0 text-bold text-blue pointer d-flex align-items-center"
                onClick={handleCopy}
              >
                Salin
                <img src={copyImage} alt="copyimg" className="ms-2" />
              </p>
            </div>
            <p className="mb-0 weight-light">Batas Waktu Pembayaran</p>
            <p className="mb-0 text-bold" style={{ fontSize: 18 }}>
              {/* 23:59:59 */}
              <Countdown
                daysInHours
                date={new Date(orderDetail?.vaExpiredDate).getTime()}
                zeroPadTime={2}
                onComplete={() => handleGetOrderDetail()}
                // renderer={renderer}
              />
            </p>
          </div>
          <div className="mt-4 px-4 bg-white" style={{ marginBottom: 140 }}>
            <Faq
              data={{
                rows: [
                  {
                    title: <b>Petunjuk VA di ATM</b>,
                    content: ChoosedBank?.data?.atm?.map((c) => <li>{c}</li>),
                  },
                  {
                    title: <b>Petunjuk VA di iBanking</b>,
                    content: ChoosedBank?.data?.iBanking?.map((c) => (
                      <li>{c}</li>
                    )),
                  },
                  {
                    title: <b>Petunjuk VA di mBanking</b>,
                    content: ChoosedBank?.data?.mBanking?.map((c) => (
                      <li>{c}</li>
                    )),
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
      <div className="fixed-bottom p-4">
        <CustomButton
          title="Belanja Lagi"
          className="w-100 mb-3"
          width="100%"
          disabled={loading}
          outline
          onClick={handleBelanjaLagi}
        />
        <CustomButton
          title="Cek Status Transaksi"
          className="w-100"
          width="100%"
          disabled={loading}
          onClick={handleCekStatus}
        />
      </div>
    </>
  );
}
