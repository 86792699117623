import CustomBackHeader from "../../shared/components/CustomBackHeader";
import LayoutApp from "../../shared/components/LayoutApp";
import ProductList from "../components/ProductList";
import Categories from "../components/Categories";
import ProductSearch from "../components/ProductSearch";
import Sticky from "react-stickynode";

export function ListProductAllContainer() {
  return (
    <LayoutApp hideBottomNavbar>
      <section className="list-all-product">
        <CustomBackHeader title="Semua Produk Pilihan Toko" noShadow={true} />
        <Sticky className="categories-sticky" enabled={true} top={52}>
          <div className="top-action">
            <ProductSearch />
            <Categories />
          </div>
        </Sticky>
        <ProductList type="all" />
      </section>
    </LayoutApp>
  );
}
