import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLogistic, getLogisticDetail } from "../cart.api";
import CustomDrawer from "../../shared/components/CustomDrawer";
import { rupiahFormat } from "../../../app/helper";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

////########### CATATAN DISINI LOGISTIK LIST DARI PRE CHECKOUT

export default function CourierSection({
  setChoosedLogistic,
  allWeight,
  allVolume,
  sellerDistrictId,
  setChoosedLogisticDetail,
  logisticData,
  indexOrder,
}) {
  const dispatch = useDispatch();
  // const { state } = useLocation();
  const {
    ChoosedAddressArr,
    ChoosedLogistic,
    ChoosedLogisticDetail,
    // logisticList,
    logisticDetailList,
    loadingLogisticDetail,
  } = useSelector((state) => state.cart);

  useEffect(() => {
    // dispatch(getLogistic());
  }, []);

  const handleGetLogisticDetail = () => {
    if (ChoosedLogistic[indexOrder]?.logistic) {
      const params = {
        weight: allWeight, // all weight items
        originDistrictId: sellerDistrictId, // district seller
        destinationDistrictId:
          ChoosedAddressArr[indexOrder]?.address?.districtId, // district buyer
        logisticCompanyId: ChoosedLogistic[indexOrder]?.logistic?.id,
        dimensi: allVolume,
      };
      dispatch(getLogisticDetail(params));
    }
  };

  useEffect(() => {
    // handleGetLogisticDetail();
  }, [ChoosedLogistic[indexOrder]?.logistic]);

  const [IsOpenCourierDrawer, setIsOpenCourierDrawer] = useState(false);
  const handleDrawerCourier = () => {
    setIsOpenCourierDrawer((p) => !p);
  };

  const [IsOpenCourierDrawerDetail, setIsOpenCourierDrawerDetail] =
    useState(false);

  const handleDrawerCourierDetail = async () => {
    await handleGetLogisticDetail();
    await setIsOpenCourierDrawerDetail((p) => true);
  };

  const handleChoosedLogistic = (val) => {
    const remap = ChoosedLogistic.map((logi) =>
      logi.id === indexOrder ? { ...logi, logistic: val } : logi,
    );
    setChoosedLogistic(remap);

    setIsOpenCourierDrawer(false);

    //remove logisticDetail if change logistic
    if (val.id !== ChoosedLogistic[indexOrder].logistic.id) {
      const resetLogDetail = ChoosedLogisticDetail.map((logi) =>
        logi.id === indexOrder ? { ...logi, logisticDetail: null } : logi,
      );
      setChoosedLogisticDetail(resetLogDetail);
    }
  };

  const handleChoosedLogisticDetail = (val) => {
    const remap = ChoosedLogisticDetail.map((logi) =>
      logi.id === indexOrder ? { ...logi, logisticDetail: val } : logi,
    );
    setChoosedLogisticDetail(remap);
    setIsOpenCourierDrawerDetail(false);
  };

  return (
    <section>
      {/* <pre>{JSON.stringify(.map(logi => logi.id === indexOrder ? ({...logi, logistic : res})), 0, 2)}</pre> */}
      {ChoosedAddressArr[indexOrder]?.address !== null && (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
            <p className="mb-0 text-bold">
              Kurir <br />
              <span className="weight-light">
                {ChoosedLogistic[indexOrder]?.logistic !== null
                  ? ChoosedLogistic[indexOrder]?.logistic?.name
                  : "-"}
              </span>
            </p>
            {ChoosedLogistic[indexOrder]?.logistic === null ? (
              <button onClick={handleDrawerCourier}>Pilih Kurir</button>
            ) : (
              <button onClick={handleDrawerCourier}>Ubah Kurir</button>
            )}
          </div>

          {ChoosedLogistic[indexOrder]?.logistic !== null && (
            <div className="d-flex justify-content-between align-items-center ">
              {ChoosedLogisticDetail[indexOrder]?.logisticDetail !== null ? (
                <p className="mb-0 text-bold">
                  {
                    ChoosedLogisticDetail[indexOrder]?.logisticDetail
                      ?.logisticLabel
                  }{" "}
                  (
                  {ChoosedLogisticDetail[indexOrder]?.logisticDetail?.estimated}
                  ) <br />
                  <span className="weight-light">
                    {rupiahFormat(
                      ChoosedLogisticDetail[indexOrder]?.logisticDetail
                        ?.totalRate,
                    )}
                  </span>
                </p>
              ) : (
                <p className="mb-0 text-bold">-</p>
              )}
              {ChoosedLogisticDetail[indexOrder]?.logisticDetail === null ? (
                <button onClick={handleDrawerCourierDetail}>
                  Pilih Layanan
                </button>
              ) : (
                <button onClick={handleDrawerCourierDetail}>
                  Ubah Layanan
                </button>
              )}
            </div>
          )}
        </>
      )}

      <CustomDrawer
        open={IsOpenCourierDrawer}
        onClose={() => setIsOpenCourierDrawer(false)}
        className="drawer-kurir "
      >
        <div className="py-5 ">
          <p className="title px-4">Pilih Kurir</p>
          <div className="px-4 d-flex flex-wrap ">
            {logisticData.map((res, i) => (
              <div
                key={i}
                className="pointer  me-2 mb-2 "
                onClick={() => handleChoosedLogistic(res)}
              >
                <div
                  className={`addbox p-3 ${
                    res.id === ChoosedLogistic[indexOrder]?.logistic?.id &&
                    "addbox-active"
                  }`}
                >
                  <p className="text-black text-bold mb-1">{res.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CustomDrawer>

      <CustomDrawer
        open={IsOpenCourierDrawerDetail}
        onClose={() => setIsOpenCourierDrawerDetail(false)}
        className="drawer-kurir "
      >
        <div className="py-5 ">
          <p className="title px-4">Pilih Layanan</p>
          <div className="px-4 d-flex flex-wrap ">
            {logisticDetailList.length === 0 ? (
              <p className="py-3 text-center text-red bg-light rounded px-3">
                <small>
                  {loadingLogisticDetail ? (
                    <Spin color="red" tip="Loading" />
                  ) : (
                    <i>
                      Layanan tidak tersedia untuk lokasi anda silahkan pilih
                      kembali alamat atau kurir!!
                    </i>
                  )}
                </small>
              </p>
            ) : logisticDetailList[0].message ? (
              <p className="py-3 text-center text-red bg-light rounded px-3">
                <small>
                  {loadingLogisticDetail ? (
                    <Spin color="red" tip="Loading" />
                  ) : (
                    <i>{logisticDetailList[0].message}</i>
                  )}
                </small>
              </p>
            ) : (
              <>
                {logisticDetailList.map((res, i) => (
                  <div key={i}>
                    {res.logisticRateDetail?.logisticRateDetailList.map(
                      (val, j) => (
                        <div
                          key={j}
                          className="pointer  me-2 mb-2 "
                          onClick={() =>
                            handleChoosedLogisticDetail({
                              ...val,
                              logisticRateHeader: res.logisticRateHeader,
                            })
                          }
                        >
                          <div
                            className={`addbox p-3 ${
                              val?.logisticCode ===
                                ChoosedLogisticDetail[indexOrder]
                                  ?.logisticDetail?.logisticCode &&
                              "addbox-active"
                            }`}
                          >
                            <p className="text-black text-bold mb-1">
                              {val?.logisticLabel}
                            </p>
                            <p className="">
                              {val?.estimated} - {rupiahFormat(val?.totalRate)}
                            </p>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </CustomDrawer>
    </section>
  );
}
