import React from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
// import filterIcon from "../../../img/profile/filterIcon.svg";

export default function CustomBackHeader({ title, href, noShadow }) {
  const navigate = useNavigate();
  const handleBack = () => navigate(href ? href : -1);
  return (
    <div
      className="px-4 header fixed-header"
      style={{
        boxShadow: noShadow ? "none" : "0px 8px 24px rgba(57, 57, 57, 0.03",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ height: 52 }}
      >
        <div className="d-flex align-items-center">
          <HiOutlineArrowLeft
            size={18}
            onClick={handleBack}
            className="me-2 pointer"
          />
          <p className="text-bold mb-0 text-red">{title}</p>
        </div>
        <div></div>
        {/* <img src={filterIcon} alt="filter" className="pointer" /> */}
      </div>
    </div>
  );
}
