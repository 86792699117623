import LayoutApp from "./LayoutApp";
import searchIcon from "../../../img/searchIcon.svg";
import { Input, AutoComplete } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiCloseCircleFill } from "react-icons/ri";

export default function SearchPage() {
  const navigate = useNavigate();
  //   const searchRef = useRef(null);

  useEffect(() => {
    // searchRef.current.focus();
  }, []);

  const handleClear = () => {
    navigate(-1); //goback
  };

  const [options, setOptions] = useState([
    { label: "aaa", value: "aaa" },
    { label: "bbbbb", value: "bbbbb" },
    { label: "cccc", value: "cccc" },
    { label: "ddddd", value: "ddddd" },
    { label: "eeeee", value: "eeeee" },
    { label: "fffff", value: "fffff" },
  ]);
  return (
    <LayoutApp>
      <div className="searchpage">
        <div className="px-4 wrapsearch fixed-header">
          <AutoComplete
            defaultOpen
            autoFocus
            options={options}
            // onClick={handleClick}
            allowClear={false}
            className="mb-0 w-100 pointer"
            // readOnly
            notFoundContent="data not found"
          >
            <Input
              className="mb-0 w-100 pointer"
              style={{
                height: 32,
                borderRadius: "100px",
                fontSize: 12,
                overflow: "hidden",
              }}
              placeholder="Cari Produk di Toko ini"
              size="small"
              prefix={<img className="mx-1" src={searchIcon} alt="google" />}
              suffix={
                <RiCloseCircleFill color="#cd3438" onClick={handleClear} />
              }
            />
          </AutoComplete>
        </div>
      </div>
    </LayoutApp>
  );
}
