import React from "react";
import checkFalseIcon from "../../../img/checkFalseIcon.svg";
import checkTrueIcon from "../../../img/checkTrueIcon.svg";
export default function RadioCustom({
  children,
  onClick,
  className,
  value,
  id,
}) {
  return (
    <div className={className} onClick={() => onClick(!value, id)}>
      <img src={value ? checkTrueIcon : checkFalseIcon} alt="checkbox" />
      {children && <div className="ms-2">{children}</div>}
    </div>
  );
}
