import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleActiveCategory } from "../store.reducer";
import Sticky from "react-stickynode";

export default function Categories() {
  const dispatch = useDispatch();
  const { listCategory, activeCategory } = useSelector((state) => state.store);

  useEffect(() => {
    return () => dispatch(handleActiveCategory("semua"));
  }, []);

  return (
    <div className="categories px-4">
      {[{ name: "semua", category_name: "Semua" }, ...listCategory].map(
        (res, i) => (
          <div
            className={`${
              activeCategory === res.name && "active"
            } boxcate px-3`}
            key={i}
            onClick={() => dispatch(handleActiveCategory(res.name))}
          >
            {res.category_icon && (
              <img
                src={res.category_icon}
                alt="category-icon"
                // className="bg-info"
                onError={(event) => (event.target.style.display = "none")}
                style={{ height: 20, objectFit: "contain" }}
              />
            )}
            <span>{res.category_name}</span>
          </div>
        ),
      )}
    </div>
  );
}
