import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trxIdGenerator } from "../../app/helper";

export const getStore = createAsyncThunk(
  "store/getStore",
  async (data, { rejectWithValue }) => {
    try {
      let filter =
        data.filterValue === "semua"
          ? `filters=[]`
          : `filters=[["category","=","${data.filterValue}"]]`.replaceAll(
              "&",
              "%26",
            );
      const url = `${process.env.REACT_APP_API_URL}/api/method/alfamind.store_owner.doctype.toko_saya.toko_saya.list/${data.store}?${filter}`;
      var config = await {
        method: "get",
        url: url,
        params: data.params,
      };
      const response = await axios(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCategory = createAsyncThunk(
  "store/getCategory",
  async (data, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/method/alfamind.store_owner.doctype.toko_saya.toko_saya.list/${data.store}`;
      var config = await {
        method: "get",
        url: url,
        // headers: {
        //     // 'Accept': 'application/json',
        //     //   'Cookie': 'full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image='
        // }
      };
      const response = await axios(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getProductDetail = createAsyncThunk(
  "store/getProductDetail",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/method/alfamind.produk.doctype.produk.produk.get_product_details`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postAddtoCart = createAsyncThunk(
  "store/postAddtoCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/cart/item/add`,
        {
          ...data,
          deviceId: localStorage.getItem("alfamindDeviceId"),
        },
        {
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBannerList = createAsyncThunk(
  "store/getBannerList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/method/alfamind.konten.doctype.banner.banner.list`,
        {
          params: data,
        },
      );
      return { data: response.data.message, limitStart: data.limit_start || 0 };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBannerDetail = createAsyncThunk(
  "store/getBannerDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/method/alfamind.konten.doctype.banner.banner.get_detail_banner`,
        {
          params: { ...data, limit_page_length: 20 },
        },
      );
      return { data: response.data.message, limitStart: data.limit_start };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getRecommendationList = createAsyncThunk(
  "store/getRecommendationList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/method/alfamind.produk.doctype.kategori_unik.kategori_unik.get_kategori_unik_detail`,
        {
          params: {
            fields:
              '["title", "subtitle", "description", "background", "banner", "total_product", "slug"]',
            slug: "rekomendasi-jualan",
            limit_start: 0,
            limit_page_length: 100,
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
