import moment from "moment";
import { rupiahFormat } from "../app/helper";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";

export default function CardProductComponent({ product, onClick }) {
  const { storeName } = useParams();
  const navigate = useNavigate();

  const isExpiredPromo = moment().isBetween(
    product.special_price_start,
    product.special_price_end,
  );

  return (
    <div className="card-product">
      <img
        src={process.env.REACT_APP_IMAGE_URL + product.featured_image}
        className="thumb px-2"
        onError={(event) =>
          (event.target.src =
            "https://nayemdevs.com/wp-content/uploads/2020/03/default-product-image.png")
        }
        alt={product.name}
        onClick={() => navigate(`/${storeName}/detail/${product.plu}`)}
      />
      <div className="wrapdesc p-2">
        <div>
          <p className="name">{product.product_name}</p>
          {product?.special_price && isExpiredPromo ? (
            <>
              <div className="mb-0 d-flex align-items-center">
                <s
                  style={{
                    fontSize: 10,
                    color: "#BABABA",
                    fontWeight: "600",
                  }}
                >
                  {rupiahFormat(product.price)}
                </s>{" "}
                <div
                  style={{
                    height: 15,
                    background: "#FDD203",
                    color: "#CD3438",
                    fontSize: 10,
                    borderRadius: 4,
                    padding: "0 2px",
                    marginLeft: 4,
                    fontWeight: 600,
                  }}
                >
                  -
                  {Math.round(
                    ((product.price - product.special_price) / product.price) *
                      100,
                  )}
                  %
                </div>
              </div>
              <div className="price mb-2">
                {rupiahFormat(product.special_price)}
              </div>
            </>
          ) : (
            <div className="price mb-2">{rupiahFormat(product.price)}</div>
          )}
        </div>
        <Button disabled={product.stock === 0} onClick={() => onClick(product)}>
          {product.stock === 0 ? "Stok Habis" : "Tambah"}
        </Button>
      </div>
    </div>
  );
}
