import { createSlice } from "@reduxjs/toolkit";
import {
  getProvince,
  getCity,
  getDistrict,
  getSubDistrict,
  postAddress,
  getAddress,
  getHistoryOrder,
  deleteAddress,
  editAddress,
} from "./profile.api";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    error: {},
    provinceList: [],
    cityList: [],
    districtList: [],
    subDistrictList: [],
    addressList: [],
    historyOrderList: [],
    historyOrderListTotal: 0,
    // isRegister: false
    choosedStatusFilter: null,
    loadingProcessTransaction: false,
    filterStartEndDate: [], //[startDate, endDate]
  },
  reducers: {
    handleChoosedStatusFilter: (state, { payload }) => {
      state.choosedStatusFilter = payload;
    },
    handleLoadingProcessTransaction: (state, { payload }) => {
      state.loadingProcessTransaction = payload;
    },
    handleResetHistoryOrderList: (state, { payload }) => {
      state.historyOrderList = [];
    },
    handleFilterDate: (state, { payload }) => {
      state.filterStartEndDate = payload;
    },
  },
  extraReducers: {
    [getProvince.pending]: (state, action) => {
      state.loading = true;
    },
    [getProvince.fulfilled]: (state, { payload }) => {
      state.provinceList = payload.province;
      state.loading = false;
    },
    [getProvince.rejected]: (state, action) => {
      state.loading = false;
    },
    //get city
    [getCity.pending]: (state, action) => {
      state.loading = true;
    },
    [getCity.fulfilled]: (state, { payload }) => {
      state.cityList = payload.city;
      state.loading = false;
    },
    [getCity.rejected]: (state, action) => {
      state.loading = false;
    },
    //get kecamatan
    [getDistrict.pending]: (state, action) => {
      state.loading = true;
    },
    [getDistrict.fulfilled]: (state, { payload }) => {
      state.districtList = payload.district;
      state.loading = false;
    },
    [getDistrict.rejected]: (state, action) => {
      state.loading = false;
    },
    //get kelurahan
    [getSubDistrict.pending]: (state, action) => {
      state.loading = true;
    },
    [getSubDistrict.fulfilled]: (state, { payload }) => {
      state.subDistrictList = payload.subDistrict;
      state.loading = false;
    },
    [getSubDistrict.rejected]: (state, action) => {
      state.loading = false;
    },
    //save alamat
    [postAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [postAddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postAddress.rejected]: (state, action) => {
      state.loading = false;
    },
    //edit alamat
    [editAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [editAddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [editAddress.rejected]: (state, action) => {
      state.loading = false;
    },
    //delete
    [deleteAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteAddress.rejected]: (state, action) => {
      state.loading = false;
    },
    //get alamat
    [getAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [getAddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // const arrPhone = JSON.parse(localStorage.getItem("alfamindPhone")) || []
      // const validAddress = []
      // for (const phone of arrPhone) {
      //     for (const item of payload.address) {
      //         if (item.receiverPhone === phone) {
      //             validAddress.push(item)
      //         }
      //     }
      // }
      state.addressList = payload.address;
    },
    [getAddress.rejected]: (state, action) => {
      state.loading = false;
      state.addressList = [];
    },

    // hiostory order
    [getHistoryOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [getHistoryOrder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.historyOrderList = payload.historyOrder.listHistoryOrder;
      state.historyOrderListTotal = payload.historyOrder.totalOrder;
    },
    [getHistoryOrder.rejected]: (state, action) => {
      state.loading = false;
      state.historyOrderList = [];
      state.historyOrderListTotal = 0;
    },
  },
});

export const {
  handleChoosedStatusFilter,
  handleLoadingProcessTransaction,
  handleResetHistoryOrderList,
  handleFilterDate,
} = profileSlice.actions;

export default profileSlice.reducer;
