import { Button } from "antd";
import React from "react";

export default function CustomButton({
  title,
  width,
  htmlType,
  disabled,
  onClick,
  loading,
  outline,
  className,
  style,
}) {
  const defaultStyle = {
    width: width || "auto",
    height: 45,
    background: "#CD3438",
    fontWeight: 600,
    borderRadius: 5,
    border: "unset",
    color: "white",
    ...style,
  };

  const outlineStyle = {
    width: width || "auto",
    height: 45,
    background: "white",
    fontWeight: 600,
    borderRadius: 5,
    border: "1px solid #CD3438 ",
    color: "#CD3438",
    ...style,
  };
  return (
    <Button
      onClick={onClick}
      className={`custom-red-buttom d-flex align-items-center justify-content-center ${className}`}
      htmlType={htmlType}
      disabled={disabled}
      loading={loading}
      style={outline ? outlineStyle : defaultStyle}>
      {title}
    </Button>
  );
}
