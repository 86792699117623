import { Button, Input } from "antd";
import React, { useState } from "react";
import CustomDrawer from "../../shared/components/CustomDrawer";
import CustomButton from "../../shared/components/CustomButton";

export default function NoteSection({
  handleChangeNote,
  indexOrder,
  Note,
  noteIcon,
}) {
  const [isOpenDrawerNote, setisOpenDrawerNote] = useState(false);
  const [TextNote, setTextNote] = useState("");

  const handleCancel = () => {
    setisOpenDrawerNote(false);
    // handleChangeNote(TextNote, indexOrder);
  };
  const handleSave = () => {
    setisOpenDrawerNote(false);
    handleChangeNote(TextNote, indexOrder);
  };
  return (
    <>
      <Input
        className="note pointer"
        readOnly
        placeholder="Tulis Catatan untuk Penjual"
        onClick={() => setisOpenDrawerNote(true)}
        style={{ marginTop: 10, height: 37 }}
        value={Note[indexOrder]?.note}
        // onChange={(e) => handleChangeNote(e, indexOrder)}
        prefix={<img src={noteIcon} className="me-1" alt="noteicon" />}
      />
      <CustomDrawer
        open={isOpenDrawerNote}
        onClose={() => setisOpenDrawerNote(false)}
        className="drawer-kurir ">
        <div className="py-5 ">
          <p className="title px-4 mb-4">Catatan</p>
          <div className="px-4 d-flex flex-wrap ">
            <Input.TextArea
              className="note mb-3"
              style={{ background: "#FCF8F8", border: `1px solid #FF767A` }}
              rows={4}
              placeholder="Tulis Catatan untuk Penjual"
              value={TextNote}
              onChange={(e) => setTextNote(e.target.value)}
            />
            <div className="d-flex w-100 justify-content-between mt-3">
              <CustomButton
                onClick={handleCancel}
                width="47.5%"
                outline
                title={"Batal"}
              />
              <CustomButton
                onClick={handleSave}
                width="47.5%"
                title={"Simpan"}
              />
            </div>
          </div>
        </div>
      </CustomDrawer>
    </>
  );
}
