import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDrawerProductData } from "../store.reducer";

export default function DetailVariant({ listItem, activeId, setData }) {
  const { drawerProductData } = useSelector((state) => state.store);

  const dispatch = useDispatch();
  const handleVariant = (val) => {
    // dispatch(handleDrawerProductData(val));
    setData(val);
  };

  return (
    <div className="wrapvariant py-3">
      <p className="px-4 mb-2 text-bold">Pilih Variasi</p>
      <div className="scroll-variant px-4">
        {listItem.map((res, i) => (
          <div
            className={`${activeId === res?.plu && "active"} boxcate px-3`}
            key={i}
            onClick={() => handleVariant(res)}>
            {res.icon} <span>{res?.variant_name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
