import {
    Navigate,
} from "react-router-dom";

export const PublicRoute = ({ children }) => {

    const auth = localStorage.getItem('auth')
    const token = localStorage.getItem('token')


    if (auth && token) return <Navigate to="/home" />
    return children;
}

export const PrivateRoute = ({ children }) => {
    const auth = localStorage.getItem('auth')
    const token = localStorage.getItem('token')

    if (!auth && !token) return <Navigate to="/" />
    return children;
}

export const rupiahFormat = (value) =>
    new Intl.NumberFormat("id-ID", {
        style: 'currency', // add Rp
        currency: "IDR",
        // maximumSignificantDigits: 20
        minimumFractionDigits: 0,
    }).format(value || 0);


export const trxIdGenerator = () => {
    const rndInt = Math.floor(Math.random() * 99999) + 1
    return rndInt
}


export const sendWhatsAppMe = (waNumber, text) => {
    if (waNumber[0] === "6") {
        const numSplit = waNumber.split("");
        numSplit[0] = "";
        numSplit[1] = "0";
        const correctNumber = numSplit.join("");
        window.open(`https://wa.me/+62${correctNumber}${text}`, "_blank");
    } else {
        window.open(
            `https://wa.me/+62${waNumber}${text}`,
            "_blank"
        );
    }
}