import LayoutApp from "../../shared/components/LayoutApp";
import { useEffect } from "react";
import { notification } from "antd";
import CustomHeader from "../../shared/components/CustomHeader";
import ListMenuProfile from "../components/ListMenuProfile";

export default function ProfileContainer() {
  useEffect(() => {
    notification.destroy();
  }, []);
  return (
    <LayoutApp>
      <div className="profile">
        <CustomHeader title="Akun" />
        <ListMenuProfile />
      </div>
    </LayoutApp>
  );
}
