import { Radio, message, notification } from "antd";
import React, { useEffect, useState } from "react";
import RadioCustom from "../../shared/components/RadioCustom";
import { IoRemoveCircle, IoAddCircle } from "react-icons/io5";
import { FiTrash } from "react-icons/fi";

import CustomButton from "../../shared/components/CustomButton";
import { Link, useParams } from "react-router-dom";
import { deleteCartProduct, getCartList } from "../cart.api";
import { useDispatch, useSelector } from "react-redux";
import { rupiahFormat } from "../../../app/helper";
import { postAddtoCart } from "../../store/store.api";
import { unwrapResult } from "@reduxjs/toolkit";

export default function CartList({ cartImages }) {
  const dispatch = useDispatch();
  const { storeName } = useParams();
  const { cartList, cartInfo } = useSelector((state) => state.cart);
  const { loading } = useSelector((state) => state.store);

  const [ChoosedCart, setChoosedCart] = useState([]);

  const [isRemoveAll, setIsRemoveAll] = useState(false);

  useEffect(() => {
    if (cartList.length === ChoosedCart.length) {
      setIsRemoveAll(() => true);
    } else {
      setIsRemoveAll(() => false);
    }
  }, [ChoosedCart]);

  const handleChoosedAll = (val) => {
    if (val) {
      setChoosedCart(() => cartList.map((r) => r.productId));
    } else {
      setChoosedCart(() => []);
    }
  };

  const handleChooseRadio = (prodId) => {
    if (ChoosedCart.includes(prodId)) {
      const filtered = ChoosedCart.filter((f) => f !== prodId);
      setChoosedCart(() => filtered);
    } else {
      setChoosedCart((prev) => [...prev, prodId]);
    }
  };

  const handleReduce = (id, qty, method) => {
    if (!localStorage.getItem("alfamindMemberId")) {
      return alert("member id not found!");
    }

    const data = {
      memberId: localStorage.getItem("alfamindMemberId"),
      applicationId: 335,
      items: [
        {
          productId: id,
          quantity: method === "add" ? qty + 1 : qty - 1,
        },
      ],
      cartId: localStorage.getItem(`${storeName}AlfamindCartId`) || null,
      cartType: "EC",
    };
    dispatch(postAddtoCart(data))
      .then(unwrapResult)
      .then((res) => {
        localStorage.setItem(`${storeName}AlfamindCartId`, res.cartItem.cartId);
        const params = {
          cartId: res.cartItem.cartId,
          applicationId: 335,
        };
        dispatch(getCartList(params));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle result here
        message.error("Stok tidak mencukupi");
      });
  };

  const handleDeleteChoosed = async () => {
    if (ChoosedCart.length !== 0) {
      const idsProd = await ChoosedCart.map((res) => ({
        productId: res,
      }));
      await handleDeleteApi(idsProd);
      await setChoosedCart(() => []);
    }
  };

  const handleDeleteApi = (prodIds) => {
    const data = {
      memberId: localStorage.getItem("alfamindMemberId"),
      cartId: localStorage.getItem(`${storeName}AlfamindCartId`) || null,
      // applicationId: 335,
      items: prodIds,
      // cartType: "EC",
    };
    dispatch(deleteCartProduct(data))
      .then(unwrapResult)
      .then((res) => {
        message.success("Produk dihapus");
        const params = {
          cartId: localStorage.getItem(`${storeName}AlfamindCartId`),
          applicationId: 335,
        };
        dispatch(getCartList(params));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle result here
        message.error("Stok tidak tersedia!!");
      });
  };

  const handleSingleDelete = (id) => {
    const idsProd = [{ productId: id }];
    handleDeleteApi(idsProd);
    const filtered = ChoosedCart.filter((f) => f !== id);
    setChoosedCart(() => filtered);
  };

  return (
    <div className="cart-list">
      {/* <pre>{JSON.stringify(ChoosedCart, 0, 2)}</pre> */}
      <div className="px-4">
        <div className="removeall ">
          <RadioCustom
            className="d-flex align-items-center pointer"
            value={isRemoveAll}
            onClick={handleChoosedAll}
            id="removeallID"
          >
            Pilih Semua Produk
          </RadioCustom>
          <p
            onClick={handleDeleteChoosed}
            className={`mb-0  ${
              ChoosedCart.length !== 0
                ? "text-black pointer text-bold"
                : "text-gray"
            } `}
          >
            Hapus Barang
          </p>
        </div>
      </div>
      <ul className="listbox px-4">
        {cartList.map((res, i) => (
          <li key={i} className="pt-3 pb-4">
            <RadioCustom
              value={ChoosedCart.includes(res.productId)}
              onClick={() => handleChooseRadio(res.productId)}
              className="d-flex align-items-start mb-3"
              id={res.productId}
            >
              <div className="d-flex">
                <img
                  className="prodimg p-2"
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    cartImages.find((f) => f.plu === res.plu)?.image
                    // ||`https://nayemdevs.com/wp-content/uploads/2020/03/default-product-image.png`
                  }
                  alt="product"
                  onError={(event) =>
                    (event.target.src =
                      "https://nayemdevs.com/wp-content/uploads/2020/03/default-product-image.png")
                  }
                />
                <div className="ms-2">
                  <p className="title text-bold mb-1">{res?.productName}</p>
                  <p className="mb-0 weight-light">{res?.brand}</p>
                  {res?.specialPrice ? (
                    <div className="">
                      <div className="mb-0 d-flex align-items-center">
                        <s
                          style={{
                            fontSize: 14,
                            color: "#BABABA",
                            fontWeight: "600",
                          }}
                        >
                          {rupiahFormat(res?.price)}
                        </s>{" "}
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            height: 19,
                            background: "#FDD203",
                            color: "#CD3438",
                            fontSize: 10,
                            borderRadius: 4,
                            padding: "0 4px",
                            marginLeft: 4,
                            fontWeight: 600,
                          }}
                        >
                          -
                          {Math.round(
                            ((res?.price - res?.specialPrice) / res?.price) *
                              100,
                          )}
                          %
                        </div>
                      </div>
                      <p className="text-bold text-red mb-0">
                        {rupiahFormat(res?.specialPrice)}
                      </p>
                    </div>
                  ) : (
                    <p className="text-bold text-red mb-0">
                      {rupiahFormat(res?.price)}
                    </p>
                  )}
                  {/* {res.discount && (
                    <p
                      className="mb-0 weight-light"
                      style={{ color: "#BABABA" }}>
                      <s>Rp {res.price + 10}</s>{" "}
                      <b className="percentdisc">-2%</b>
                    </p>
                  )}
                  <p className="text-bold text-red mb-0">
                    {rupiahFormat(res.price)}
                  </p> */}
                </div>
              </div>
            </RadioCustom>
            <div
              className="d-flex justify-content-between align-items-center w-100 "
              style={{ paddingLeft: 28 }}
            >
              <FiTrash
                size={20}
                className="pointer"
                onClick={() => handleSingleDelete(res.productId)}
              />
              <div className="counter d-flex justify-content-between align-items-center">
                <IoRemoveCircle
                  onClick={() =>
                    res.quantity <= 1
                      ? () => {}
                      : handleReduce(res.productId, res.quantity, "remove")
                  }
                  className={`reducebutt ${res.quantity <= 1 && "disabled"} ${
                    loading && "disabled"
                  }`}
                  size={24}
                />
                <span className={`text-bold ${loading && "text-gray"}`}>
                  {res.quantity}
                </span>
                <IoAddCircle
                  className={`reducebutt ${loading && "disabled"}`}
                  size={24}
                  onClick={() =>
                    handleReduce(res.productId, res.quantity, "add")
                  }
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className=" d-flex justify-content-between align-items-center px-4 wrapfoot fixed-bottom">
        <div className="">
          <p className="text-bold mb-0">Total Pesanan</p>
          <p className="text-bold mb-0 text-red" style={{ fontSize: 16 }}>
            {/* Rp {Data.map((m) => m.price).reduce((a, b) => a + b, 0)} */}
            {rupiahFormat(cartInfo.totalAmountPaid)}
          </p>
        </div>
        <Link to={`/${storeName}/cart/checkout`}>
          <CustomButton width={140} title={`Checkout (${cartList.length})`} />
        </Link>
      </div>
    </div>
  );
}
