import { Spin } from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Masonry from "react-responsive-masonry";
import { useParams } from "react-router-dom";
import CardProductComponent from "../../../components/CardProductComponent";
import CustomBackHeader from "../../shared/components/CustomBackHeader";
import LayoutApp from "../../shared/components/LayoutApp";
import Loading from "../../shared/components/Loading";
import { getBannerDetail, getProductDetail } from "../store.api";
import DetailDrawer from "../components/DetailDrawer";
import { handleDrawerProductData } from "../store.reducer";
import { unwrapResult } from "@reduxjs/toolkit";

const pattern = /src="\/files\/([^"]+)"/g;

export function PromoDetailContainer() {
  const { name } = useParams();
  const dispatch = useDispatch();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const { bannerDetail, bannerProducts, loading } = useSelector(
    (state) => state.store,
  );
  const [limitStart, setLimitStart] = useState(0);

  useEffect(() => {
    dispatch(getBannerDetail({ name, limit_start: limitStart }));
  }, [dispatch, limitStart, name]);

  const handleLoadMore = () => {
    setLimitStart(limitStart + 20);
  };

  const handleOpenDrawer = (val) => {
    setIsOpenDrawer(true);
    if (val.is_group) {
      const params = {
        plu: val.plu,
      };
      dispatch(getProductDetail(params))
        .then(unwrapResult)
        .then((res) => {
          dispatch(handleDrawerProductData(res.message));
        })
        .catch((err) => console.log(err));
    } else {
      dispatch(handleDrawerProductData(val));
    }
  };

  const onCloseDrawer = () => {
    setIsOpenDrawer((prev) => !prev);
    dispatch(handleDrawerProductData({}));
  };
  return (
    <LayoutApp hideBottomNavbar>
      {loading && <Loading />}
      <section className="banner-detail">
        <CustomBackHeader title="Promo" />
        {bannerDetail && (
          <>
            <img
              src={process.env.REACT_APP_IMAGE_URL + bannerDetail.image}
              className="banner-img"
              alt={bannerDetail.name}
            />
            <div className="p-4 mb-2 bg-white">
              <h1 className="title">{bannerDetail.title}</h1>
            </div>
            <div className="p-4 bg-white">
              <div className="mb-5">
                {bannerDetail.description && (
                  <div
                    className="html"
                    dangerouslySetInnerHTML={{
                      __html: bannerDetail.description.replaceAll(
                        pattern,
                        `src="${process.env.REACT_APP_IMAGE_URL}/files/$1"`,
                      ),
                    }}
                  ></div>
                )}
                {bannerDetail.term_and_condition && (
                  <div
                    className="html mt-4"
                    dangerouslySetInnerHTML={{
                      __html: bannerDetail.term_and_condition.replaceAll(
                        pattern,
                        `src="${process.env.REACT_APP_IMAGE_URL}/files/$1"`,
                      ),
                    }}
                  ></div>
                )}
              </div>
              <InfiniteScroll
                dataLength={bannerProducts.length}
                next={handleLoadMore}
                hasMore={bannerDetail.hasMore}
                loader={
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <Spin /> <b className="text-red ms-3 mb-2">Loading...</b>
                  </div>
                }
              >
                <Masonry columnsCount={2} gutter="15px">
                  {bannerProducts.map((product, i) => {
                    return (
                      <CardProductComponent
                        key={product.name}
                        product={product}
                        onClick={handleOpenDrawer}
                      />
                    );
                  })}
                </Masonry>
              </InfiniteScroll>
            </div>
            <DetailDrawer open={isOpenDrawer} onClose={onCloseDrawer} />
          </>
        )}
      </section>
    </LayoutApp>
  );
}
