import React from "react";
import LayoutApp from "../../shared/components/LayoutApp";
import { Link } from "react-router-dom";

import ListAddress from "../components/ListAddress";
export default function ListAddressContainer() {
  return (
    <LayoutApp>
      <div className="list-address">
        <ListAddress />
      </div>
    </LayoutApp>
  );
}
