import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Masonry from "react-responsive-masonry";

import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import CardProductComponent from "../../../components/CardProductComponent";
import { getCategory, getProductDetail } from "../store.api";
import { handleDrawerProductData } from "../store.reducer";
import DetailDrawer from "./DetailDrawer";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function ProductList({ type }) {
  const { storeName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const handleOpenDrawer = (val) => {
    setIsOpenDrawer(true);
    if (val.is_group) {
      const params = {
        plu: val.plu,
      };
      dispatch(getProductDetail(params))
        .then(unwrapResult)
        .then((res) => {
          dispatch(handleDrawerProductData(res.message));
        })
        .catch((err) => console.log(err));
    } else {
      dispatch(handleDrawerProductData(val));
    }
  };

  const { listProduct: dataProduct, loading } = useSelector(
    (state) => state.store,
  );

  const listProduct =
    type === "preview" ? dataProduct.slice(0, 9) : dataProduct;

  useEffect(() => {
    const dataCategory = {
      store: storeName,
    };
    dispatch(getCategory(dataCategory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    setIsOpenDrawer((prev) => !prev);
    dispatch(handleDrawerProductData({}));
  };

  return (
    <div className="productlist px-4 pb-4">
      {type === "preview" && (
        <div className="d-flex justify-content-between align-items-center pb-4">
          <h4 className="mb-0 section-title">Pilihan Toko</h4>
          <Link
            to={`/${storeName}/list-product-all`}
            className="btn-border-red rounded-pill"
          >
            Lihat Semua
          </Link>
        </div>
      )}
      {loading ? (
        <div className="d-flex justify-content-center mt-4 min-h-container">
          <Spin tip="Loading..." />
        </div>
      ) : listProduct.length > 0 ? (
        <Masonry columnsCount={2} gutter="15px">
          {listProduct.map((product, i) => (
            <CardProductComponent
              key={product.name}
              product={product}
              onClick={handleOpenDrawer}
            />
          ))}
          {type === "preview" && dataProduct.length > 9 && (
            <Link to={`/${storeName}/list-product-all`}>
              <div className="card-btn-see-all">
                <h5>Lihat Semua</h5>
              </div>
            </Link>
          )}
        </Masonry>
      ) : (
        <h4 className="not-found-product min-h-container">
          Produk tidak ditemukan.
        </h4>
      )}
      <DetailDrawer open={isOpenDrawer} onClose={onClose} />
    </div>
  );
}
