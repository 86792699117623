import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/auth.reducer';
import sharedReducer from '../features/shared/shared.reducer';
import profileReducer from '../features/profile/profile.reducer';
import storeReducer from '../features/store/store.reducer';
import cartReducer from '../features/cart/cart.reducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    store: storeReducer,
    cart: cartReducer,
    shared: sharedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
