export default [
    {
        id: 10,
        label: "Pesanan dibuat",
        textColor: "#3F3F3F",
        bgColor: "#F2F2F2",
    },
    {
        id: 11,
        label: "Menunggu Pembayaran",
        textColor: "#E14A4D",
        bgColor: "#FBEFEF",
    },
    {
        id: 12,
        label: "Pesanan sudah dibayar",
        textColor: "#3F3F3F",
        bgColor: "#F2F2F2",
    },
    {
        id: 13,
        label: "Pesanan diproses",
        textColor: "#045BA7",
        bgColor: "#E6F3FE",
    },
    {
        id: 14,
        label: "Pesanan dalam pengiriman",
        textColor: "#3F3F3F",
        bgColor: "#F2F2F2",
    },
    {
        id: 15,
        label: "Pengiriman selesai",
        textColor: "#86C43E",
        bgColor: "#F3F9EB",
    },
    { id: 18, label: "Batal", textColor: "#3F3F3F", bgColor: "#F2F2F2" },
    { id: 112, label: "Gagal", textColor: "#3F3F3F", bgColor: "#F2F2F2" },
    { id: 336, label: "Kode Bayar Kadaluarsa", textColor: "#3F3F3F", bgColor: "#F2F2F2" },
];

