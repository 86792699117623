import React from "react";
import { useSelector } from "react-redux";
// import CustomDrawer from "../../shared/components/CustomDrawer";
// import { IoAddCircleOutline } from "react-icons/io5";
// import { Link, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";

export default function AddressSection({
  // ChoosedAddressArr,
  handleDrawerAddress,
  indexOrder,
}) {
  // const { storeName } = useParams();
  const { ChoosedAddressArr } = useSelector((state) => state.cart);

  return (
    <section>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <p className="mb-0 text-bold">Alamat Pengiriman</p>
        {ChoosedAddressArr[indexOrder]?.address !== null ? (
          <button onClick={() => handleDrawerAddress(indexOrder)}>
            Ubah Alamat
          </button>
        ) : (
          <button onClick={() => handleDrawerAddress(indexOrder)}>
            Pilih Alamat
          </button>
        )}
      </div>
      {ChoosedAddressArr[indexOrder]?.address !== null && (
        <>
          <p className="mb-1 weight-light">
            {ChoosedAddressArr[indexOrder]?.address?.receiverName}
          </p>
          <p className="text-gray addresstext">
            {ChoosedAddressArr[indexOrder]?.address?.receiverPhone} <br />
            {ChoosedAddressArr[indexOrder]?.address?.address}{" "}
            {ChoosedAddressArr[indexOrder]?.address?.subDistrictName}{" "}
            {ChoosedAddressArr[indexOrder]?.address?.districtName}{" "}
            {ChoosedAddressArr[indexOrder]?.address?.cityName}{" "}
            {ChoosedAddressArr[indexOrder]?.address?.provinceName},{" "}
            {ChoosedAddressArr[indexOrder]?.address?.postCode}
          </p>
        </>
      )}

      {/* <CustomDrawer
        open={IsopenDrawerAddress}
        onClose={handleDrawerAddress}
        className="drawer-address ">
        {addressList.length === 0 ? (
          <Link to={`/${storeName}/profile/list-address/create`}>
            <div className="py-5 px-4">
              <p className="title">Pilih Alamat</p>
              <div className="box text-center px-3 py-4">
                <p className="text-red text-bold mb-0">Tambah Alamat</p>
                <p className="">
                  Anda belum pernah menambahkan Alamat pengiriman
                </p>
                <IoAddCircleOutline size={24} color="#E14A4D" />
              </div>
            </div>
          </Link>
        ) : (
          <div className="py-5 ">
            <p className="title px-4">Pilih Alamat</p>
            <div className=" addwrap px-4">
              {addressList.map((res, i) => (
                <div
                  key={i}
                  className="pointer"
                  onClick={() => setChoosedAddressArr(res)}>
                  <div
                    className={`addbox p-3 ${
                      res?.id === ChoosedAddressArr?.id && "addbox-active"
                    }`}>
                    <p className="text-black text-bold mb-1">
                      {res.receiverName}
                    </p>
                    <p className="mb-0">{res?.receiverPhone}</p>
                    <p className="address-text">
                      {res.address} {res.subDistrictName} {res.districtName}{" "}
                      {res.cityName} {res.provinceName}, {res.postCode}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </CustomDrawer> */}
    </section>
  );
}
