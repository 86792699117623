import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAddressList = createAsyncThunk(
  "shared/getAddressList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/addresses`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getImageBulk = createAsyncThunk(
  "shared/getImageBulk",
  async (params, { rejectWithValue }) => {
    // try {
    //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resource/Produk?fields=["sku","featured_image"]&filters=[["sku","in","${params.sku}"]]`, {
    //         // params: params,
    //         headers: {
    //             'Accept': 'application/json',
    //             'Authorization': `Bearer ${localStorage.getItem('token')}`
    //         }
    //     })
    //     return response.data
    // } catch (error) {
    //     return rejectWithValue(error)
    // }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/method/alfamind.produk.doctype.produk.produk.get_product_details`,
        { params },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
