import React from "react";
import LayoutApp from "../../shared/components/LayoutApp";
import { Link } from "react-router-dom";
import CreateAddressForm from "../components/CreateAddressForm";
import CustomBackHeader from "../../shared/components/CustomBackHeader";

export default function CreateAddressContainer() {
  return (
    <LayoutApp hideBottomNavbar>
      <div className="create-address py-5">
        <CustomBackHeader title="Tambah Alamat" />
        <CreateAddressForm />
      </div>
    </LayoutApp>
  );
}
