import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, message } from "antd";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { rupiahFormat } from "../../../app/helper";
import { getAddress } from "../../profile/profile.api";
import { getPreCheckout, postCheckout } from "../cart.api";

import LayoutApp from "../../shared/components/LayoutApp";
import CustomBackHeader from "../../shared/components/CustomBackHeader";
import noteIcon from "../../../img/profile/noteIcon.svg";
import CustomButton from "../../shared/components/CustomButton";
import CourierSection from "../components/CourierSection";
import AddressSection from "../components/AddressSection";
import { getImageBulk } from "../../shared/shared.api";
import {
  handleBluePrint,
  handleChoosedAddress,
  handleChoosedLogistic,
  handleChoosedLogisticDetail,
  handleNote,
} from "../cart.reducer";
import NoteSection from "../components/NoteSection";
import Loading from "../../shared/components/Loading";

export default function CheckoutContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storeName } = useParams();
  const {
    orderList,
    loadingPreCheckout,
    orderInfoAll,
    logisticDetailList,
    ChoosedAddressArr,
    loading,
    ChoosedLogistic,
    ChoosedLogisticDetail,
    Note,
    isBluePrint,
  } = useSelector((state) => state.cart);

  const handlePreCheckout = async () => {
    const addressParams = await {
      memberId: localStorage.getItem("alfamindMemberId"),
      deviceId: localStorage.getItem("alfamindDeviceId"),
    };
    await dispatch(getAddress(addressParams));
    const params = await {
      cartId: localStorage.getItem(`${storeName}AlfamindCartId`),
    };
    await dispatch(getPreCheckout(params))
      .then(unwrapResult)
      .then((res) => {
        if (res.orderDetail.orders.length) {
          handleFillAllBlueprint(res.orderDetail.orders);
        }
      });
  };

  useEffect(() => {
    handlePreCheckout();
  }, []);

  const fillNoteBlueprint = (arr) => {
    const noteEmpty = arr.map((n, i) => ({ id: i, note: "" }));
    dispatch(handleNote(noteEmpty));
  };

  const fillChoosedLogisticBlueprint = (arr) => {
    const logEmpty = arr.map((n, i) => ({ id: i, logistic: null }));
    setChoosedLogistic(logEmpty);
  };

  const fillChoosedLogisticDetailBlueprint = (arr) => {
    const logDetailEmpty = arr.map((n, i) => ({
      id: i,
      logisticDetail: null,
    }));
    setChoosedLogisticDetail(logDetailEmpty);
  };

  const fillAddressBluePrint = (arr) => {
    const addressEmpty = arr.map((n, i) => ({ id: i, address: null }));
    dispatch(handleChoosedAddress(addressEmpty));
  };

  const handleFillAllBlueprint = async (arr) => {
    if (isBluePrint === false) {
      await fillNoteBlueprint(arr);
      await fillAddressBluePrint(arr);
      await fillChoosedLogisticBlueprint(arr);
      await fillChoosedLogisticDetailBlueprint(arr);

      await dispatch(handleBluePrint(true)); // all blueprint setted
    }
  };

  useEffect(() => {
    if (orderList.length !== 0) {
      handleGetImageBulk();
    }
  }, [orderList]);

  const [CheckoutImages, setCheckoutImages] = useState([]);

  const handleGetImageBulk = async () => {
    const listPlu = await orderList
      .map((_) => _.items.map((v) => v.plu))
      .flat();
    for (const plu of listPlu) {
      dispatch(getImageBulk({ plu: plu }))
        .then(unwrapResult)
        .then((res) => {
          setCheckoutImages((p) => [
            ...p,
            { plu: plu, image: res?.message?.featured_image },
          ]);
        })
        .catch((err) => {
          console.log("failed get image!");
        });
    }
  };
  const [IsShowDetailTotal, setIsShowDetailTotal] = useState(false);
  // const [ChoosedLogistic, setChoosedLogistic] = useState([]); //////////////////////////////////////////////////
  const setChoosedLogistic = (v) => {
    dispatch(handleChoosedLogistic(v));
  };
  // const [ChoosedLogisticDetail, setChoosedLogisticDetail] = useState([]);
  const setChoosedLogisticDetail = (v) => {
    dispatch(handleChoosedLogisticDetail(v));
  };
  // const [Note, setNote] = useState([]);

  const handleChangeNote = (value, id) => {
    const renote = Note.map((n) => (n.id === id ? { ...n, note: value } : n));
    dispatch(handleNote(renote));
  };

  const handleDrawerAddress = (index) => {
    navigate(`/${storeName}/profile/list-address/${index}`);
  };
  const handleDetailTotal = () => setIsShowDetailTotal((prev) => !prev);

  const handleCheckout = () => {
    const deliveryArray = orderList.map((val, i) => {
      // prettier-ignore
      const { carrierId,logisticName, destination, origin } = ChoosedLogisticDetail[i]?.logisticDetail?.logisticRateHeader;
      // prettier-ignore
      const { logisticCode, logisticLabel, rate, totalRate, estimated } = ChoosedLogisticDetail[i]?.logisticDetail;

      return {
        fee: totalRate,
        feeDiscount: 0,
        feeFinal: totalRate,
        storeTimeSlot: null,
        method: "1",
        logisticCompanyId: carrierId, //company id
        type: "2",
        number: "",
        memberAddressId: ChoosedAddressArr[i].address?.id,
        nameReceiver: ChoosedAddressArr[i].address?.receiverName,
        phoneReceiver: ChoosedAddressArr[i].address?.receiverPhone,
        logisticFromCode: origin,
        logisticToCode: destination,
        logisticCode: logisticCode,
        estimated: estimated,
        longitude: null,
        latitude: null,
        note: Note[i]?.note,
      };
    });

    if (!localStorage.getItem("alfamindDeviceId")) {
      return alert("Device ID notFound!");
    }

    const data = {
      ...orderInfoAll,
      // deviceId: 12345678910 || localStorage.getItem("alfamindDeviceId"),
      orders: orderList.map((val, index) => ({
        ...val,
        delivery: deliveryArray[index],
      })),
    };
    delete data.paymentMethod;

    dispatch(postCheckout({ orderDetail: data }))
      .then(unwrapResult)
      .then((res) => {
        const dataState = {
          totalAmount: res.orderDetail.totalAmount, //subtot
          totalAmountFinal: res.orderDetail.totalAmountFinal,
          totalAmountPaid: res.orderDetail.totalAmountPaid,
          totalDeliveryFeeFinal: res.orderDetail.totalDeliveryFeeFinal,
          totalDeliveryFeeDiscount: res.orderDetail.totalDeliveryFeeDiscount,
          totalMarginSOPromo: res.orderDetail.totalMarginSOPromo,
          paymentMethod: orderInfoAll?.paymentMethod,
        };

        navigate(`/${storeName}/cart/payment/${res.orderDetail.orderNumber}`, {
          state: dataState,
        });
        localStorage.removeItem(`${storeName}AlfamindCartId`);
        dispatch(handleBluePrint(false));
      })
      .catch((err) => {
        message.error("Checkout Failed!");
      });
  };

  const totalAllDeliveryFee = ChoosedLogisticDetail.map(
    (r) => r?.logisticDetail?.totalRate,
  )
    .filter(Number)
    .reduce((a, b) => a + b, 0);

  return (
    <LayoutApp hideBottomNavbar>
      {loadingPreCheckout && <Loading />}
      <div className="checkout">
        <CustomBackHeader title="Checkout" />
        {/* <pre>
          <small>{JSON.stringify(totalAllDeliveryFee, 0, 2)}</small>
          <small>{JSON.stringify(ChoosedLogistic, 0, 2)}</small>
          <small>{JSON.stringify(ChoosedLogisticDetail, 0, 2)}</small>
        </pre> */}
        <p className="text-red text-bold px-4 pb-0">Barang</p>
        {orderList.map((res, i) => (
          <div key={i}>
            <div className="px-4">
              <p className="text-bold text-black mb-1">
                Pengiriman #{i + 1} (
                {res.items.map((q) => q.quantity).reduce((a, b) => a + b, 0)}{" "}
                Barang)
                {/* ({JSON.stringify(val)} Barang) */}
              </p>
              <p className="text-gray mb-3">{res?.seller?.cityName}</p>
              {res.items.map((val, j) => (
                <div className="box py-4  border-top" key={j}>
                  <div className="d-flex">
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        CheckoutImages.find((f) => f.plu === val?.plu)?.image
                      }
                      alt=""
                      className="thumb me-2"
                    />
                    <div>
                      <p className="mb-1 text-bold">{val?.productName}</p>
                      <p className="mb-0 qty">
                        {val?.weight}g{" "}
                        <b className="text-black">{val?.quantity} Barang</b>
                      </p>
                      {val?.specialPrice ? (
                        <div className="">
                          <div className="mb-0 d-flex align-items-center">
                            <s
                              style={{
                                fontSize: 14,
                                color: "#BABABA",
                                fontWeight: "600",
                              }}
                            >
                              {rupiahFormat(val?.price)}
                            </s>{" "}
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                height: 19,
                                background: "#FDD203",
                                color: "#CD3438",
                                fontSize: 10,
                                borderRadius: 4,
                                padding: "0 4px",
                                marginLeft: 4,
                                fontWeight: 600,
                              }}
                            >
                              -
                              {Math.round(
                                ((val?.price - val?.specialPrice) /
                                  val?.price) *
                                  100,
                              )}
                              %
                            </div>
                          </div>
                          <p className="text-bold text-red mb-0">
                            {rupiahFormat(val?.specialPrice)}
                          </p>
                        </div>
                      ) : (
                        <p className="text-bold text-red mb-0">
                          {rupiahFormat(val?.price)}
                        </p>
                      )}
                      {/* <p className="weight-light mb-0">
                        {rupiahFormat(val?.price)}
                      </p> */}
                    </div>
                  </div>
                </div>
              ))}

              <NoteSection
                handleChangeNote={handleChangeNote}
                indexOrder={i}
                Note={Note}
                noteIcon={noteIcon}
              />
            </div>
            <div className="py-4 border-top addresswrap px-4">
              <AddressSection
                handleDrawerAddress={handleDrawerAddress}
                ChoosedAddressArr={ChoosedAddressArr}
                indexOrder={i}
              />
              <CourierSection
                logisticData={res?.seller?.logisticCompany}
                indexOrder={i}
                // ChoosedAddressArr={ChoosedAddressArr}
                // ChoosedLogistic={ChoosedLogistic}
                setChoosedLogistic={setChoosedLogistic}
                allWeight={res?.items
                  .map((x) => x.weight * x.quantity)
                  .reduce((a, b) => a + b, 0)}
                allVolume={res?.items
                  .map((x) => x.volume * x.quantity)
                  .reduce((a, b) => a + b, 0)}
                sellerDistrictId={res?.seller?.districtId}
                // ChoosedLogisticDetail={ChoosedLogisticDetail}
                setChoosedLogisticDetail={setChoosedLogisticDetail}
              />

              {/* {ChoosedAddressArr !== null && ( */}
              <>
                {IsShowDetailTotal ? (
                  <div className="d-flex justify-content-between align-items-center mb-2 mt-4">
                    <p className="mb-0 text-bold">Total</p>
                    <span className="d-flex align-items-center ">
                      {rupiahFormat(
                        res?.subTotalAmountPaid +
                          (ChoosedLogisticDetail[i]?.logisticDetail
                            ?.totalRate || 0),
                      )}
                      <HiChevronUp
                        size={20}
                        className="pointer"
                        onClick={handleDetailTotal}
                      />
                    </span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between align-items-center mb-2 mt-4 ">
                    <p className="mb-0 text-bold">Subtotal</p>
                    <span className="d-flex align-items-center ">
                      {rupiahFormat(
                        res?.subTotalAmountPaid +
                          (ChoosedLogisticDetail[i]?.logisticDetail
                            ?.totalRate || 0),
                      )}
                      <HiChevronDown
                        size={20}
                        className="pointer"
                        onClick={handleDetailTotal}
                      />
                    </span>
                  </div>
                )}
                {IsShowDetailTotal && (
                  <div className="weight-light pb-3 border-bottom">
                    <p className="d-flex justify-content-between align-items-center mb-1">
                      <span>
                        Subtotal (
                        {res.items
                          .map((q) => q.quantity)
                          .reduce((a, b) => a + b, 0)}{" "}
                        Barang)
                      </span>{" "}
                      {rupiahFormat(res?.subTotalAmount)}
                    </p>
                    <p className="d-flex justify-content-between align-items-center mb-1">
                      <span>Diskon</span> -{" "}
                      {rupiahFormat(
                        // res?.subTotalProductBulkyPromo +
                        res?.subTotalSpecialPricePromo,
                      )}
                    </p>
                    {/* <p className="d-flex justify-content-between align-items-center mb-1">
                      <span>Margin (Potongan Langsung)</span> -{" "}
                      {rupiahFormat(res?.subTotalMarginSOPromo)}
                    </p> */}
                    <p className="d-flex justify-content-between align-items-center mb-1">
                      <span>Biaya Kirim</span>{" "}
                      {rupiahFormat(
                        ChoosedLogisticDetail[i]?.logisticDetail?.totalRate,
                      )}
                    </p>
                    <p className="d-flex justify-content-between align-items-center mb-1">
                      <span>Diskon Biaya Kirim</span> Rp 0
                    </p>
                  </div>
                )}
              </>
              {/* )} */}
            </div>

            <div className=" d-flex justify-content-between align-items-center px-4 wrapfoot fixed-bottom">
              <div className="">
                <p className="text-bold mb-0">Total Pesanan</p>
                <p className="text-bold mb-0 text-red" style={{ fontSize: 16 }}>
                  {rupiahFormat(
                    orderInfoAll?.totalAmountPaid + totalAllDeliveryFee,
                  )}
                </p>
              </div>
              {/* <Link to={`/${storeName}/cart/payment`}> */}
              <CustomButton
                onClick={handleCheckout}
                width={160}
                title=" Pilih Pembayaran"
                loading={loading}
                disabled={
                  ChoosedAddressArr.map((add) => add.address).includes(null) ||
                  ChoosedLogistic.map((log) => log.logistic).includes(null) ||
                  ChoosedLogisticDetail.map(
                    (logdet) => logdet.logisticDetail,
                  ).includes(null)
                }
              />

              {/* </Link> */}
            </div>
          </div>
        ))}
      </div>
    </LayoutApp>
  );
}
