import React from "react";
import LayoutApp from "../../shared/components/LayoutApp";
import { Link } from "react-router-dom";
import CreateAddressForm from "../components/CreateAddressForm";
import CustomBackHeader from "../../shared/components/CustomBackHeader";
import EditAddressForm from "../components/EditAddressForm";

export default function EditAddressContainer() {
  return (
    <LayoutApp hideBottomNavbar>
      <div className="create-address py-5">
        <CustomBackHeader title="Ubah Alamat" />
        <EditAddressForm />
      </div>
    </LayoutApp>
  );
}
