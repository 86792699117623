import { Drawer } from "antd";

export default function CustomDrawer({ children, open, onClose, className }) {
  return (
    <Drawer
      className={className}
      // title="Drawer with extra actions"
      placement="bottom"
      width={420}
      closable={false}
      bodyStyle={{
        padding: 0,
        zIndex: 999999,
      }}
      style={{
        maxWidth: 420,
        margin: `0 auto`,
        zIndex: 999999,
        //   position: "relative",
      }}
      onClose={onClose}
      open={open}
      height={"auto"}>
      {children}
    </Drawer>
  );
}
