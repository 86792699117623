import React from "react";
import LayoutApp from "../../shared/components/LayoutApp";
import { Link } from "react-router-dom";
import HeaderListTransaction from "../components/HeaderListTransaction";
import ListTransaction from "../components/ListTransaction";

export default function ListTransactionContainer() {
  return (
    <LayoutApp>
      <div className="list-transaction">
        <HeaderListTransaction />
        <ListTransaction />
      </div>
    </LayoutApp>
  );
}
