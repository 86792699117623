import { Spin } from "antd";
import React from "react";

export default function Loading() {
  return (
    <div
      className="d-flex align-items-center justify-content-center loading_custom"
      style={{ zIndex: 999999999 }}>
      <Spin tip={<b>Loading...</b>} />
    </div>
  );
}
