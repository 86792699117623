import React from "react";

import emptyCartImage from "../../../img/emptyCartImage.svg";

export default function EmptyCart() {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center p-5 "
      style={{ height: "90vh" }}>
      <img className="mb-4" src={emptyCartImage} alt="empty" />
      <p className="text-center">
        Keranjang Anda kosong. <br /> Isi dengan barang-barang belanja dan
        dapatkan kemudahan dan keuntungan dalam berbelanja.
      </p>
    </div>
  );
}
