import { AutoComplete, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { FiChevronDown } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

import {
  editAddress,
  getCity,
  getDistrict,
  getProvince,
  getSubDistrict,
  postAddress,
} from "../profile.api";
import CustomButton from "../../shared/components/CustomButton";
import Loading from "../../shared/components/Loading";

export default function EditAddressForm() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  const dispatch = useDispatch();
  const { provinceList, cityList, districtList, subDistrictList, loading } =
    useSelector((state) => state.profile);
  const [formAlamat] = Form.useForm();

  const [ProvinceId, setProvinceId] = useState(null);
  const [CityId, setCityId] = useState(null);
  const [DistrictId, setDistrictId] = useState(null);
  useEffect(() => {
    dispatch(getProvince());
  }, []);

  const loadData = async () => {
    const provinceObj = await provinceList.find(
      (f) => f.provinceBpsId === state.provinceId,
    );

    await setProvinceId(provinceObj?.provinceBpsId || null);
    await setCityId(state?.cityId || null);
    await setDistrictId(state?.districtId || null);
    await formAlamat.setFieldsValue({
      ...state,
      provinceId: provinceObj?.provinceId,
    });
  };

  useEffect(() => {
    loadData();
  }, [provinceList]);

  useEffect(() => {
    if (ProvinceId) {
      const params = {
        provinceBpsId: ProvinceId,
      };
      dispatch(getCity(params));
      resetChoosedField(["cityId", "districtId", "subDistrictId"]);
    }
  }, [ProvinceId]);

  useEffect(() => {
    if (CityId) {
      const params = {
        cityId: CityId,
      };
      dispatch(getDistrict(params));
      resetChoosedField(["districtId", "subDistrictId"]);
    }
  }, [CityId]);

  useEffect(() => {
    if (DistrictId) {
      const params = {
        districtId: DistrictId,
      };
      dispatch(getSubDistrict(params));
      resetChoosedField(["subDistrictId"]);
    }
  }, [DistrictId]);

  const onReset = () => {
    formAlamat.resetFields();
  };

  const resetChoosedField = (arrToReset) => {
    for (const name of arrToReset) {
      formAlamat.setFieldsValue({
        [name]: null,
      });
    }
  };

  const onFinish = (values) => {
    const data = {
      memberId: localStorage.getItem("alfamindMemberId"),
      memberAddress: values,
    };
    data.memberAddress.deviceId = state?.deviceId;
    data.memberAddress.id = state?.id;

    dispatch(editAddress(data))
      .then(unwrapResult)
      .then((res) => {
        message.success("Alamat berhasil di ubah.");
        // const arrPhoneBefore =
        //   JSON.parse(localStorage.getItem("alfamindPhone")) || [];
        // const updatedArr = [...arrPhoneBefore, res.address[0].receiverPhone];
        // localStorage.setItem(
        //   "alfamindPhone",
        //   JSON.stringify([...new Set(updatedArr)])
        // );

        onReset();
        handleBack();
      })
      .catch((rejectedValueOrSerializedError) => {
        message.error("Address not saved!!");
      });
  };

  // const [DisableButton, setDisableButton] = useState(true);
  // Watch all values
  // const values = Form.useWatch([], formAlamat);
  // useEffect(() => {
  //   if (values) {
  //     const allValuesArr = Object?.values(values);
  //     if (
  //       allValuesArr.includes("") ||
  //       allValuesArr.includes(undefined) ||
  //       allValuesArr.includes(null)
  //     ) {
  //       setDisableButton(() => true);
  //     } else {
  //       setDisableButton(() => false);
  //     }
  //   }
  // }, [values]);

  return (
    <div className="px-4 pt-4">
      {loading && <Loading />}
      <Form form={formAlamat} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="receiverName"
          label="Nama Anda"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="receiverPhone"
          label="Nomor Telepon"
          rules={[
            {
              pattern: new RegExp(/^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/g),
              message: "Phone number format not match!",
            },
            { required: true, message: "This field is required!" },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="address"
          label="Alamat Lengkap"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="provinceId"
          label="Provinsi"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Select
            suffixIcon={<FiChevronDown size={20} />}
            size="large"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={provinceList.map((res) => ({
              value: res.provinceId,
              label: res.provinceName,
              ...res,
            }))}
            optionFilterProp="children"
            onChange={(val, obj) => setProvinceId(() => obj.provinceBpsId)}
          />
        </Form.Item>
        <Form.Item
          name="cityId"
          label="Kabupaten/Kota"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Select
            suffixIcon={<FiChevronDown size={20} />}
            size="large"
            showSearch
            options={cityList.map((res) => ({
              value: res.cityId,
              label: res.cityName,
              ...res,
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(val, obj) => setCityId(() => obj.cityId)}
          />
        </Form.Item>
        <Form.Item
          name="districtId"
          label="Kecamatan"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Select
            suffixIcon={<FiChevronDown size={20} />}
            size="large"
            showSearch
            options={districtList.map((r) => ({
              value: r.districtId,
              label: r.districtName,
              ...r,
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(val, obj) => setDistrictId(() => obj.districtId)}
          />
        </Form.Item>
        <Form.Item
          name="subDistrictId"
          label="Kelurahan"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Select
            suffixIcon={<FiChevronDown size={20} />}
            size="large"
            showSearch
            options={subDistrictList.map((r) => ({
              value: r.subDistrictId,
              label: r.subDistrictName,
              ...r,
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            // onChange={(val, obj) => console.log(obj)}
          />
        </Form.Item>
        {/* <div className="row">
          <div className="col-6">
            <Form.Item
              name="rt"
              label="RT"
              rules={[{ required: true, message: "This field is required!" }]}>
              <Input size="large" type="number" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item
              name="rw"
              label="RW"
              rules={[{ required: true, message: "This field is required!" }]}>
              <Input size="large" type="number" />
            </Form.Item>
          </div>
        </div> */}
        <Form.Item
          name="postCode"
          label="Kode Pos"
          rules={[
            { required: true, message: "This field is required!" },
            { min: 5, message: "Pos code must be 5 characters." },
            { max: 5, message: "Pos code must be 5 characters." },
          ]}
        >
          <Input size="large" type="number" />
        </Form.Item>
        <div
          className="fixed-bottom px-4"
          style={{ height: 100, paddingTop: 16 }}
        >
          <CustomButton
            htmlType="submit"
            title="Ubah"
            className="w-100"
            width="100%"
            // disabled={DisableButton}
          />
        </div>
      </Form>
    </div>
  );
}
