import LayoutApp from "../../shared/components/LayoutApp";
import { useEffect, useState } from "react";
import { message, notification } from "antd";

import virtualIcon from "../../../img/payment/virtualIcon.svg";
import geraiIcon from "../../../img/payment/geraiIcon.svg";
import walletIcon from "../../../img/payment/walletIcon.svg";

import {
  getHistoryOrderDetail,
  postPayment,
  triggerTransactionExpired,
  triggerWaitingPayment,
} from "../cart.api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import ListBankSection from "../components/ListBankSection";
import Loading from "../../shared/components/Loading";
import PendingPayment from "../components/PendingPayment";
import SuccessPayment from "../components/SuccessPayment";
import axios from "axios";

export default function PaymentContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderNumber, storeName } = useParams();
  const { loadingOrderDetail, loading, orderDetail } = useSelector(
    (state) => state.cart,
  );

  //   10 >   Pesanan dibuat
  // 11 >   Menunggu Pembayaran
  // 12 >   Pesanan sudah dibayar
  // 13 >   Pesanan diproses
  // 14 >   Pesanan dalam pengiriman
  // 15 >   Pengiriman selesai
  // 18 >  Batal
  // 112 > Gagal

  useEffect(() => {
    notification.destroy();
  }, []);

  const handleGetOrderDetail = () => {
    dispatch(getHistoryOrderDetail({ orderNumber: orderNumber }))
      .then(unwrapResult)
      .then((res) => {
        const statusApproveToPage = [10, 11, 12];
        if (!statusApproveToPage.includes(res.orderDetail.orderStatus)) {
          navigate(`/${storeName}/profile/list-transaction`);
        }
      });
  };
  useEffect(() => {
    handleGetOrderDetail();
    handleGetVirtualAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumber]);

  const [LoadingBank, setLoadingBank] = useState(false);
  const handleGetVirtualAccount = () => {
    setLoadingBank(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/method/alfamind.json.banks`)
      .then(async (res) => {
        setLoadingBank(false);
        // await setListVirtualAccount(res.data.message);
        const payment = await [
          {
            groupId: 971,
            icon: "",
            groupName: "Saldo",
            groupList: [],
          },
          {
            groupId: 972,
            icon: virtualIcon,
            groupName: "Virtual Account",
            groupList: res.data.message,
          },
          {
            groupId: 973,
            groupName: "Gerai Retail",
            icon: geraiIcon,
            groupList: [],
          },
          {
            groupId: 974,
            groupName: "Dompet Digital",
            icon: walletIcon,
            groupList: [],
          },
        ];
        await setBankData(payment);
      })
      .catch((error) => {
        setLoadingBank(false);
        alert("Error : list bank not found!");
      });
  };

  const [bankData, setBankData] = useState([]);

  const [IsActiveMenu, setIsActiveMenu] = useState(0);
  const [ChoosedBank, setChoosedBank] = useState(null);

  const handleActiveMenu = (v) => setIsActiveMenu(() => v);
  const handleChoosedBank = (v) => setChoosedBank(() => v);

  const handlePayment = () => {
    const data = {
      orderNumber: orderNumber,
      paymentMethodId: ChoosedBank.id,
    };
    dispatch(postPayment(data))
      .then(unwrapResult)
      .then((res) => {
        const crmPayload = orderDetail?.deliveries?.order_per_seller?.map(
          (order) => ({
            items: order.deliveryItems.items.map((item) => ({
              productId: item.productId,
              productName: item.name,
              plu: item.sku,
            })),
            delivery: {
              nameReceiver: order.deliveryAddress.intendedReceiver,
              phoneReceiver: order.deliveryAddress.phone,
            },
          }),
        );

        dispatch(
          triggerTransactionExpired({
            order_id: orderDetail?.orderNumber,
            orders: crmPayload,
            store_owner: localStorage.getItem("alfamindStorePhone"),
          }),
        );

        dispatch(
          triggerWaitingPayment({
            order_id: orderDetail?.orderNumber,
            orders: crmPayload,
            store_owner: localStorage.getItem("alfamindStorePhone"),
          }),
        );
        handleGetOrderDetail();
      })
      .catch((err) => {
        message.error("payment failed");
      });
  };

  const pageObject = {
    status10: (
      <ListBankSection
        bankData={bankData}
        handleActiveMenu={handleActiveMenu}
        handleChoosedBank={handleChoosedBank}
        IsActiveMenu={IsActiveMenu}
        ChoosedBank={ChoosedBank}
        // state={state}
        loading={loading}
        LoadingBank={LoadingBank}
        handlePayment={handlePayment}
      />
    ),
    status11: <PendingPayment handleGetOrderDetail={handleGetOrderDetail} />,
    status12: <SuccessPayment />,
  };

  return (
    <LayoutApp hideBottomNavbar>
      {loadingOrderDetail && <Loading />}
      <div className="payment ">
        {pageObject[`status${orderDetail?.orderStatus}`]}
        {/* {orderDetail?.orderStatus === 10 && (
          <ListBankSection
            bankData={bankData}
            handleActiveMenu={handleActiveMenu}
            handleChoosedBank={handleChoosedBank}
            IsActiveMenu={IsActiveMenu}
            ChoosedBank={ChoosedBank}
            // state={state}
            loading={loading}
            handlePayment={handlePayment}
          />
        )}
        {orderDetail?.orderStatus === 11 && (
          <PendingPayment handleGetOrderDetail={handleGetOrderDetail} />
        )}

        {orderDetail?.orderStatus === 12 && <SuccessPayment />} */}
      </div>
    </LayoutApp>
  );
}
