import CartContainer from "../features/cart/containers/CartContainer";
import StoreContainer from "../features/store/containers/StoreContainer";
import ProfileContainer from "../features/profile/containers/ProfileContainer";
import DetailProductContainer from "../features/store/containers/DetailProductContainer";
import SearchPage from "../features/shared/components/SearchPage";
import ListAddressContainer from "../features/profile/containers/ListAddressContainer";
import ListTransactionContainer from "../features/profile/containers/ListTransactionContainer";
import CreateAddressContainer from "../features/profile/containers/CreateAddressContainer";
import DetailTransactionContainer from "../features/profile/containers/DetailTransactionContainer";
import CheckoutContainer from "../features/cart/containers/CheckoutContainer";
import PaymentContainer from "../features/cart/containers/PaymentContainer";
import EditAddressContainer from "../features/profile/containers/EditAddressContainer";
import TrackingOrderContainer from "../features/profile/containers/TrackingOrderContainer";
import InvoiceContainer from "../features/profile/containers/InvoiceContainer";
import { PromoDetailContainer } from "../features/store/containers/PromoDetailContainer";
import { ListPromoContainer } from "../features/store/containers/ListPromoContainer";
import TNCContainer from "../features/tnc/containers/TNCContainer";
import { ListProductAllContainer } from "../features/store/containers/ListProductAllContainer";

const routes = [
  {
    name: "Store",
    isSidebarLink: false,
    path: "/:storeName",
    element: <StoreContainer />,
    isPrivate: false,
  },
  {
    name: "Detail Product",
    isSidebarLink: false,
    path: "/:storeName/detail/:idProduct",
    element: <DetailProductContainer />,
    isPrivate: false,
  },
  {
    name: "Cart",
    isSidebarLink: false,
    path: "/:storeName/cart",
    element: <CartContainer />,
    isPrivate: false,
  },
  {
    name: "Checkout",
    isSidebarLink: false,
    path: "/:storeName/cart/checkout",
    element: <CheckoutContainer />,
    isPrivate: false,
  },
  {
    name: "Payment",
    isSidebarLink: false,
    path: "/:storeName/cart/payment/:orderNumber",
    element: <PaymentContainer />,
    isPrivate: false,
  },
  {
    name: "TNC",
    isSidebarLink: false,
    path: "/:storeName/cart/payment/:orderNumber/:articleName",
    element: <TNCContainer />,
    isPrivate: false,
  },
  {
    name: "Profile",
    isSidebarLink: false,
    path: "/:storeName/profile",
    element: <ProfileContainer />,
    isPrivate: false,
  },
  {
    name: "List Address",
    isSidebarLink: false,
    path: "/:storeName/profile/list-address",
    element: <ListAddressContainer />,
    isPrivate: false,
  },
  {
    name: "List Address Pickedup",
    isSidebarLink: false,
    path: "/:storeName/profile/list-address/:pickId",
    element: <ListAddressContainer />,
    isPrivate: false,
  },
  {
    name: "Create Address",
    isSidebarLink: false,
    path: "/:storeName/profile/list-address/create",
    element: <CreateAddressContainer />,
    isPrivate: false,
  },
  {
    name: "Edit Address",
    isSidebarLink: false,
    path: "/:storeName/profile/list-address/edit",
    element: <EditAddressContainer />,
    isPrivate: false,
  },
  {
    name: "List Transaction",
    isSidebarLink: false,
    path: "/:storeName/profile/list-transaction",
    element: <ListTransactionContainer />,
    isPrivate: false,
  },
  {
    name: "Detail Transaction",
    isSidebarLink: false,
    path: "/:storeName/profile/list-transaction/:orderNumber",
    element: <DetailTransactionContainer />,
    isPrivate: false,
  },
  {
    name: "Tracking Order",
    isSidebarLink: false,
    path: "/:storeName/profile/list-transaction/:orderNumber/track",
    element: <TrackingOrderContainer />,
    isPrivate: false,
  },
  {
    name: "Invoice",
    isSidebarLink: false,
    path: "/:storeName/profile/list-transaction/:orderNumber/invoice",
    element: <InvoiceContainer />,
    isPrivate: false,
  },
  {
    name: "Search Page",
    isSidebarLink: false,
    path: "/search",
    element: <SearchPage />,
    isPrivate: false,
  },
  {
    name: "Semua Promo",
    isSidebarLink: false,
    path: "/:storeName/list-product-all",
    element: <ListProductAllContainer />,
    isPrivate: false,
  },
  {
    name: "Semua Promo",
    isSidebarLink: false,
    path: "/:storeName/list-promo",
    element: <ListPromoContainer />,
    isPrivate: false,
  },
  {
    name: "Promo Detail",
    isSidebarLink: false,
    path: "/:storeName/promo-detail/:name",
    element: <PromoDetailContainer />,
    isPrivate: false,
  },
  {
    name: "Blank Page",
    isSidebarLink: false,
    path: "*",
    element: (
      <div className="blankpage text-red ">
        {" "}
        <b>TOKOVIRTUALKU</b>{" "}
      </div>
    ),
    isPrivate: false,
  },
];

export default routes;
