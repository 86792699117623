import LayoutApp from "../../shared/components/LayoutApp";
import EmptyCart from "../components/EmptyCart";
import CartList from "../components/CartList";
import { useEffect, useState } from "react";
import { notification } from "antd";
import CustomHeader from "../../shared/components/CustomHeader";
import { useDispatch, useSelector } from "react-redux";
import { getCartList } from "../cart.api";
import { useParams } from "react-router-dom";
import { getImageBulk } from "../../shared/shared.api";
import { unwrapResult } from "@reduxjs/toolkit";
import { handleBluePrint } from "../cart.reducer";

export default function CartContainer() {
  const { storeName } = useParams();
  const dispatch = useDispatch();

  const handleGetCart = () => {
    const params = {
      cartId: localStorage.getItem(`${storeName}AlfamindCartId`),
      applicationId: 335,
    };
    dispatch(getCartList(params));
  };
  useEffect(() => {
    notification.destroy();
    handleGetCart();
    dispatch(handleBluePrint(false)); // all blueprint setted
  }, []);

  const { cartList, isBluePrint } = useSelector((state) => state.cart);
  const [isCartEmpty, setisCartEmpty] = useState(3);
  useEffect(() => {
    setisCartEmpty(cartList.length === 0 ? 1 : 2);
    handleGetImageBulk();
  }, [cartList]);

  const [cartImages, setCartImages] = useState([]);

  const handleGetImageBulk = async () => {
    const listSKU = await cartList.map((r) => r.plu);
    for (const sku of listSKU) {
      dispatch(getImageBulk({ plu: sku }))
        .then(unwrapResult)
        .then((res) => {
          // imgAll.push({ plu: sku, image: res?.message?.featured_image });
          setCartImages((p) => [
            ...p,
            { plu: sku, image: res?.message?.featured_image },
          ]);
        })
        .catch((err) => {
          console.log("failed get image!");
        });
    }
  };

  return (
    <LayoutApp>
      <div className="cart">
        <CustomHeader title="Keranjang" />
        {isCartEmpty === 1 ? (
          <EmptyCart />
        ) : isCartEmpty === 2 ? (
          <CartList cartImages={cartImages} />
        ) : (
          <></>
        )}
      </div>
    </LayoutApp>
  );
}
