import { createSlice } from "@reduxjs/toolkit";
import {
  getCategory,
  getProductDetail,
  getStore,
  postAddtoCart,
  getBannerList,
  getBannerDetail,
  getRecommendationList,
} from "./store.api";

const storeSlice = createSlice({
  name: "store",
  initialState: {
    loading: false,
    loadingAddCart: false,
    error: {},
    listProduct: [],
    listCategory: [],
    productDetail: {},
    activeCategory: "semua",
    drawerProductData: {},
    searchText: "",
    listBanner: [],
    hasMoreBanner: false,
    bannerDetail: null,
    bannerProducts: [],
    listRecommendation: [],
    contentRecommendation: {},
  },
  reducers: {
    handleActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
    handleDrawerProductData: (state, action) => {
      state.drawerProductData = action.payload;
    },
    handleSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
  extraReducers: {
    [getStore.pending]: (state) => {
      state.loading = true;
    },
    [getStore.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listProduct = payload.message.items;
    },
    [getStore.rejected]: (state) => {
      state.loading = false;
    },
    //get category
    [getCategory.pending]: (state) => {
      state.loading = true;
    },
    [getCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listCategory = payload?.message?.categories;
    },
    [getCategory.rejected]: (state) => {
      state.loading = false;
    },
    //prod detail api
    [getProductDetail.pending]: (state) => {
      state.loading = true;
    },
    [getProductDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.productDetail = payload.message;
    },
    [getProductDetail.rejected]: (state) => {
      state.loading = false;
    },
    // add to cart
    [postAddtoCart.pending]: (state) => {
      state.loadingAddCart = true;
    },
    [postAddtoCart.fulfilled]: (state, { payload }) => {
      state.loadingAddCart = false;
    },
    [postAddtoCart.rejected]: (state) => {
      state.loadingAddCart = false;
    },
    // get banner list
    [getRecommendationList.pending]: (state) => {
      state.loading = true;
    },
    [getRecommendationList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const { message } = payload;

      state.listRecommendation = message.item.products;
      state.contentRecommendation = {
        background: message.item.background,
        description: message.item.description,
      };
    },
    [getRecommendationList.rejected]: (state) => {
      state.loading = false;
    },
    // get banner list
    [getBannerList.pending]: (state) => {
      state.loading = true;
    },
    [getBannerList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const { data, limitStart } = payload;

      if (data.length !== 0) {
        state.hasMoreBanner = true;

        if (limitStart === 0) {
          state.listBanner = data;
        } else {
          state.listBanner = [...state.listBanner, ...data];
        }
      } else {
        state.hasMoreBanner = false;
      }
    },
    [getBannerList.rejected]: (state) => {
      state.loading = false;
    },
    // get banner detail
    [getBannerDetail.pending]: (state, { meta }) => {
      if (meta.arg.limit_start === 0) {
        state.loading = true;
      }
    },
    [getBannerDetail.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const { products, ...rest } = payload.data;

      if (products.length !== 0) {
        state.bannerDetail = { ...rest, hasMore: true };
        if (payload.limitStart === 0) {
          state.bannerProducts = products;
        } else {
          state.bannerProducts = [...state.bannerProducts, ...products];
        }
      } else {
        if (payload.limitStart === 0) {
          state.bannerProducts = [];
        }
        state.bannerDetail = { ...rest, hasMore: false };
      }
    },
    [getBannerDetail.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  handleActiveCategory,
  handleDrawerProductData,
  handleSearchText,
} = storeSlice.actions;

export default storeSlice.reducer;
