import { Button, Input } from "antd";
import React from "react";

import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import searchIcon from "../../../img/searchIcon.svg";
import { getStore } from "../store.api";
import { handleSearchText } from "../store.reducer";

export default function ProductSearch() {
  const dispatch = useDispatch();
  const { storeName } = useParams();
  const { activeCategory, searchText } = useSelector((state) => state.store);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const data = {
        filterValue: activeCategory,
        store: storeName,
        params: {
          search: searchText,
        },
      };
      dispatch(getStore(data));
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText, activeCategory]);

  useEffect(() => {
    return () => dispatch(handleSearchText(""));
  }, []);

  return (
    <section className="search-bar">
      <div className="px-4 py-2">
        <Input
          style={{ height: 32, borderRadius: "100px", fontSize: 12 }}
          placeholder="Cari Produk di Toko ini"
          prefix={<img className="mx-1" src={searchIcon} alt="search icon" />}
          className=""
          value={searchText}
          allowClear
          onChange={(e) => dispatch(handleSearchText(e.target.value))}
        />
      </div>
    </section>
  );
}
