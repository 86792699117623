import React from "react";
import { Route, Routes } from "react-router-dom";

import routes from "./app/routes";

function App() {
  return (
    <Routes>
      {routes.map(({ path, element, isPrivate }) => (
        <Route
          key={path}
          path={path}
          element={
            element
            // isPrivate ?
            //   <PrivateRoute>{element}</PrivateRoute>
            //   :
            //   <PublicRoute>{element}</PublicRoute>
          }
        />
      ))}
    </Routes>
  );
}

export default App;
