import React from "react";
import CustomButton from "../../shared/components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { rupiahFormat } from "../../../app/helper";

import successPaymentLogo from "../../../img/payment/successPaymentLogo.svg";

export default function SuccessPayment() {
  const { storeName, orderNumber } = useParams();
  const navigate = useNavigate();
  const handleBackToStore = () => navigate(`/${storeName}`);
  const { loading, orderDetail } = useSelector((state) => state.cart);

  const handleDetailOrder = () => {
    const href = `/${storeName}/profile/list-transaction/${orderNumber}`;
    navigate(href);
  };

  return (
    <>
      <div className="py-5 px-4 success-payment">
        <p
          className="text-red text-bold text-center"
          style={{ fontSize: 16, marginBottom: 100 }}>
          Transaksi Selesai
        </p>

        <div className="d-flex justify-content-center mt-3">
          <div className="successbox">
            <div className="oval oval-lefttop"></div>
            <div className="oval oval-righttop"></div>
            <div className="oval oval-leftbottom"></div>
            <div className="oval oval-rightbottom"></div>
            <img
              src={successPaymentLogo}
              alt="success-payment-logo"
              className="paymentlogo"
            />
            <div className="text-center">
              <p className=" text-bold  mb-0" style={{ fontSize: 16 }}>
                Transaksi Berhasil
              </p>
              <p className="mb-1" style={{ fontSize: 12 }}>
                Pembayaran kamu sudah selesai!
              </p>
              <p className="mb-5 text-gray">
                {moment(orderDetail?.paymentDate).format("DD MMM YYYY HH:mm")}
              </p>

              <p className="mb-0 ">Total Pembayaran</p>

              <p
                className=" text-bold"
                style={{ fontSize: 24, marginBottom: 90 }}>
                {rupiahFormat(
                  orderDetail?.totalAmountFinal +
                    orderDetail?.totalMarginSOPromo
                )}
              </p>
            </div>
            <div className="orderbox">
              <div className="text-center">
                <p className="mb-0" style={{ fontSize: 12 }}>
                  Nomor Order
                </p>
                <p className="mb-0 text-bold">{orderDetail?.orderNumber}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <small>
        <pre>{JSON.stringify(orderDetail.paymentDate, 0, 2)}</pre>
        orderNumber
        <pre>{JSON.stringify(orderDetail.orderNumber, 0, 2)}</pre> 
      </small> */}
      <div className="fixed-bottom p-4">
        <CustomButton
          title="Lihat Detail Pesanan"
          className="w-100 mb-3"
          width="100%"
          disabled={loading}
          outline
          onClick={handleDetailOrder}
        />
        <CustomButton
          title="Kembali ke Toko"
          className="w-100"
          width="100%"
          disabled={loading}
          onClick={handleBackToStore}
        />
      </div>
    </>
  );
}
