import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { IoAddCircle } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import searchIcon from "../../../img/searchIcon.svg";

export default function HeaderListAddress({ FilterText, setFilterText }) {
  const { storeName } = useParams();
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);

  return (
    <div className="px-4 header fixed-header">
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ height: 52 }}>
        <div className="d-flex align-items-center">
          <HiOutlineArrowLeft size={18} onClick={handleBack} className="me-2" />
          <p className="text-bold text-red mb-0">Daftar Alamat</p>
        </div>
        <Link to={`/${storeName}/profile/list-address/create`}>
          <IoAddCircle className="text-red" size={20} />
        </Link>
      </div>

      <Input
        style={{ height: 32, borderRadius: "100px", fontSize: 12 }}
        placeholder="Cari Alamat"
        prefix={<img className="mx-1" src={searchIcon} />}
        className="mb-2"
        allowClear
        value={FilterText}
        // onPressEnter={(v) => console.log(v.target.value)}
        onChange={(e) => setFilterText(() => e.target.value)}
      />
    </div>
  );
}
