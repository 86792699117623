import React from "react";
import pinIcon from "../../../img/profile/loc.png";
import addressListIcon from "../../../img/profile/trans.png";
import { FiChevronRight, FiChevronsRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
export default function ListMenuProfile() {
  const { storeName } = useParams();

  const menuData = [
    {
      title: "Daftar Alamat",
      href: `/${storeName}/profile/list-address`,
      icon: pinIcon,
    },
    {
      title: "Daftar Transaksi",
      href: `/${storeName}/profile/list-transaction`,
      icon: addressListIcon,
    },
  ];
  return (
    <ul className="menu-list px-4">
      {menuData.map((res, i) => (
        <Link to={res.href} key={i}>
          <li className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={res.icon} className="me-3" style={{ width: "18px" }} />
              {res.title}
            </div>
            <FiChevronRight size={18} color="#C5C5C5" />
          </li>
        </Link>
      ))}
    </ul>
  );
}
