import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { trxIdGenerator } from "../../app/helper";

export const getCartList = createAsyncThunk(
  "cart/getCartList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/cart/item/get`,
        {
          params: params,
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCartProduct = createAsyncThunk(
  "cart/deleteCartProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/cart/item/delete`,
        data,
        {
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPreCheckout = createAsyncThunk(
  "cart/getPreCheckout",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/order/preCheckout/get`,
        {
          params: params,
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLogistic = createAsyncThunk(
  "cart/getLogistic",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/logistic/company/get`,
        {
          params: params,
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLogisticDetail = createAsyncThunk(
  "cart/getLogisticDetail",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/logistic/rate/detail/get`,
        {
          params: params,
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const triggerTransactionExpired = createAsyncThunk(
  "cart/triggerTransactionExpired",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_WORKFLOW_API_URL}/webhook/alfa/crm_expired_transaction`,
        body,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const triggerWaitingPayment = createAsyncThunk(
  "cart/triggerWaitingPayment",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_WORKFLOW_API_URL}/webhook/alfa/crm_waiting_payment`,
        body,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postCheckout = createAsyncThunk(
  "cart/postCheckout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/order/postCheckout/`,
        data,
        {
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const postPayment = createAsyncThunk(
  "cart/postPayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/payment/process`,
        data,
        {
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getHistoryOrderDetail = createAsyncThunk(
  "cart/getHistoryOrderDetail",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/transactionhistory/historyOrderDetail/get`,
        {
          params: params,
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getHistoryOrderDetailBulk = createAsyncThunk(
  "cart/getHistoryOrderDetailBulk",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAT_API_URL}/trans/v1/transactionhistory/historyOrderDetail/get`,
        {
          params: params,
          headers: {
            token: localStorage.getItem("alfamindToken"),
            trxId: trxIdGenerator(),
            id: localStorage.getItem("alfamindId"),
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
