import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import store from "../../../img/store.svg";
import storeGray from "../../../img/storeGray.svg";

import cart from "../../../img/cart.svg";
import cartGray from "../../../img/cartGray.svg";

import profile from "../../../img/profile.svg";
import profileGray from "../../../img/profileGray.svg";
import { Badge } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCartList } from "../../cart/cart.api";
import { useEffect } from "react";

export default function NavbarBottom() {
  const { storeName } = useParams();
  const dispatch = useDispatch();

  const { cartList, cartInfo } = useSelector((state) => state.cart);
  const handleGetCart = () => {
    const params = {
      cartId: localStorage.getItem(`${storeName}AlfamindCartId`),
      applicationId: 335,
    };
    dispatch(getCartList(params));
  };
  useEffect(() => {
    handleGetCart();
  }, []);

  const navList = [
    {
      id: 1,
      href: `/${storeName}`,
      label: "Toko",
      iconDisabled: storeGray,
      icon: store,
      end: true,
    },
    {
      id: 2,
      href: `/${storeName}/cart`,
      label: "Keranjang",
      iconDisabled: cartGray,
      withBadge: true,
      icon: cart,
      end: false,
    },
    {
      id: 3,
      href: `/${storeName}/profile`,
      label: "Akun",
      iconDisabled: profileGray,
      icon: profile,
      end: false,
    },
  ];

  return (
    <div className="navbar-bottom">
      {navList.map((res) => (
        <div key={res.id} className="navbox">
          <NavLink to={res.href} end={res.end}>
            {({ isActive, isPending }) => (
              <>
                {res.withBadge ? (
                  <Badge
                    size="small"
                    count={cartList.length}
                    // style={{ fontSize: 5 }}
                  >
                    <img
                      src={isActive ? res.icon : res.iconDisabled}
                      alt={res.label}
                    />
                  </Badge>
                ) : (
                  <img
                    src={isActive ? res.icon : res.iconDisabled}
                    alt={res.label}
                  />
                )}
                <p className={`${isActive ? "title-bold" : `title`} mb-0`}>
                  {res.label}
                </p>
              </>
            )}
          </NavLink>
        </div>
      ))}
    </div>
  );
}
