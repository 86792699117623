import React from "react";
import searchIcon from "../../../img/searchIcon.svg";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";

export default function CustomHeader({ title }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/search");
  };
  return (
    <div
      className="fixed-header d-flex justify-content-between align-items-center px-4"
      style={{ height: 52, boxShadow: `0px 8px 24px rgba(57, 57, 57, 0.03)` }}>
      <p className="text-bold mb-0">{title}</p>

      {/* <Input
        style={{ height: 32, borderRadius: "100px", fontSize: 12 }}
        placeholder="Cari Produk di Toko ini"
        prefix={<img className="mx-1" src={searchIcon} alt="google" />}
        className="mb-0 w-50 pointer"
        onClick={handleClick}
        readOnly
      /> */}
    </div>
  );
}
