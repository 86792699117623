import React, { useState } from "react";
import { Button, Input } from "antd";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { IoAddCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import filterIcon from "../../../img/profile/filterIcon.svg";
import CustomDrawer from "../../shared/components/CustomDrawer";
import dataStatus from "../../../app/dataStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  handleChoosedStatusFilter,
  handleFilterDate,
  handleResetHistoryOrderList,
} from "../profile.reducer";
import moment from "moment";

export default function HeaderListTransaction() {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false);
  const handleDrawerFilter = () => setIsOpenDrawerFilter((prev) => !prev);

  const { choosedStatusFilter, loadingProcessTransaction, filterStartEndDate } =
    useSelector((state) => state.profile);
  const dispatch = useDispatch();
  // const filterTransaction = [
  //   "Semua",
  //   "Pesanan Dalam Pengiriman",
  //   "Pesanan Dibuat",
  //   "Pengiriman Selesai",
  //   "Pesanan Sudah Dibayar",
  //   "Pesanan Diproses",
  //   "Transaksi Gagal",
  //   "Menunggu Pembayaran",
  //   "Transaksi Batal",
  // ];

  // [startdate, enddate]
  const filterPeriod = [
    { value: [], label: "Semua Tanggal Transaksi" },
    {
      value: [
        moment().add(-30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      label: "30 Hari Terakhir",
    },
    {
      value: [
        moment().add(-90, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      label: "90 Hari Terakhir",
    },
  ];

  const handleDate = async (arrDate) => {
    await dispatch(handleResetHistoryOrderList());
    await dispatch(handleFilterDate(arrDate));
  };

  const handleStatus = async (id) => {
    await dispatch(handleResetHistoryOrderList());
    await dispatch(handleChoosedStatusFilter(id));
  };

  const handleReset = () => {
    handleStatus(null);
    handleDate([]);
  };

  // console.log(
  //   moment().add(-30, "days").format("YYYY-MM-DD"),
  //   "start date moment"
  // );
  // console.log(moment().format("YYYY-MM-DD"), "end date moment");
  return (
    <>
      <div className="px-4 header fixed-header">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ height: 52 }}>
          <div className="d-flex align-items-center">
            <HiOutlineArrowLeft
              size={18}
              onClick={handleBack}
              className="me-2"
            />
            <p className="text-bold mb-0 text-red">Daftar Transaksi</p>
          </div>
          <img
            src={filterIcon}
            alt="filter"
            className="pointer"
            onClick={handleDrawerFilter}
          />
        </div>
      </div>
      <CustomDrawer
        open={isOpenDrawerFilter}
        onClose={loadingProcessTransaction ? () => {} : handleDrawerFilter}>
        <div className="drawer-filter-transaction px-4 pt-4 pb-5 ">
          <div className="d-flex justify-content-between align-items-center text-bold pb-3 border-bottom mb-4">
            Filter{" "}
            <span
              className="pointer"
              style={{ color: "#BABABA" }}
              onClick={handleReset}>
              Reset
            </span>
          </div>
          <p className="text-bold">Transaksi</p>

          <div className="d-flex flex-wrap">
            <Button
              disabled={
                choosedStatusFilter !== null && loadingProcessTransaction
              }
              loading={
                choosedStatusFilter === null && loadingProcessTransaction
              }
              className={`box px-3 me-2 mb-2 ${
                choosedStatusFilter === null && "active"
              }`}
              onClick={() => handleStatus(null)}>
              Semua
            </Button>
            {dataStatus.map((res, i) => (
              <Button
                disabled={
                  res.id !== choosedStatusFilter && loadingProcessTransaction
                }
                loading={
                  res.id === choosedStatusFilter && loadingProcessTransaction
                }
                className={`box px-3 me-2 mb-2 ${
                  res.id === choosedStatusFilter && "active"
                }`}
                key={i}
                onClick={() => handleStatus(res.id)}>
                {res.label}
              </Button>
            ))}
          </div>
          <p className="text-bold mt-4">Periode Pesanan</p>
          <div className="d-flex flex-wrap">
            {filterPeriod.map((res, i) => (
              <Button
                onClick={() => handleDate(res.value)}
                key={i}
                disabled={
                  JSON.stringify(res.value) !==
                    JSON.stringify(filterStartEndDate) &&
                  loadingProcessTransaction
                }
                loading={
                  JSON.stringify(res.value) ===
                    JSON.stringify(filterStartEndDate) &&
                  loadingProcessTransaction
                }
                className={`box px-3 me-2 mb-2 ${
                  JSON.stringify(res.value) ===
                    JSON.stringify(filterStartEndDate) && "active"
                }`}>
                {res.label}
              </Button>
            ))}
          </div>
        </div>
      </CustomDrawer>
    </>
  );
}
