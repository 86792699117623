import React from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import cartIcon from "../../../img/cartBlack.svg";
import { useSelector } from "react-redux";
import { Badge } from "antd";

export default function HeaderDetailProduct() {
  const navigate = useNavigate();
  const { storeName } = useParams();
  const { cartList } = useSelector((state) => state.cart);
  
  const handleBack = () => navigate(`/${storeName}`);

  return (
    <div
      className="px-4 header fixed-header"
      style={{
        boxShadow: `0px 8px 24px rgba(57, 57, 57, 0.03)`,
      }}
    >
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ height: 52 }}
      >
        <div className="d-flex align-items-center">
          <HiOutlineArrowLeft
            size={18}
            onClick={handleBack}
            className="me-2 pointer"
          />
        </div>
        <Link to={`/${storeName}/cart`} replace>
          <Badge size="small" count={cartList.length}>
            <img src={cartIcon} alt="cart" className="pointer" />
          </Badge>
        </Link>
      </div>
    </div>
  );
}
