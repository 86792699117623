import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LayoutApp from "../../shared/components/LayoutApp";
import { rupiahFormat } from "../../../app/helper";
import { getHistoryOrderDetail } from "../../cart/cart.api";
import CreateAddressForm from "../components/CreateAddressForm";
import CustomBackHeader from "../../shared/components/CustomBackHeader";
import copyImage from "../../../img/profile/copyImage.svg";
import dataStatus from "../../../app/dataStatus";
import Loading from "../../shared/components/Loading";
import { message } from "antd";
import { getImageBulk } from "../../shared/shared.api";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomButton from "../../shared/components/CustomButton";

export default function DetailTransactionContainer() {
  const { storeName, orderNumber } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orderDetail, loadingOrderDetail } = useSelector(
    (state) => state.cart,
  );
  useEffect(() => {
    dispatch(getHistoryOrderDetail({ orderNumber: orderNumber }));
  }, [orderNumber]);

  const {
    orderStatus,
    paymentDate,
    createDate,
    deliveries,
    totalAmountPaid,
    paymentPaidDate,
    totalDeliveryFeeFinal,
    totalDiscount,
    totalDeliveryFeeDiscount,
    totalAmount,
    totalAmountFinal,
    totalMarginSOPromo,
    paymentDescriptionIn,
  } = orderDetail;

  const [CheckoutImages, setCheckoutImages] = useState([]);

  const handleGetImageBulk = async (arrSku) => {
    // const listSKU = arrSku.map((_) => _.items.map((v) => v.sku));
    for (const sku of arrSku) {
      dispatch(getImageBulk({ plu: sku }))
        .then(unwrapResult)
        .then((res) => {
          // imgAll.push({ plu: sku, image: res?.message?.featured_image });
          setCheckoutImages((p) => [
            ...p,
            { plu: sku, image: res?.message?.featured_image },
          ]);
        })
        .catch((err) => {
          console.log("failed get image!");
        });
    }
  };

  const handleGetSKU = async () => {
    const allOrders = await deliveries?.order_per_seller.map((s) => s);
    const allSku = await allOrders
      .map((item) => item.deliveryItems.items)
      .flat();
    const arrSKU = allSku.map((s) => s.skuSeller);
    handleGetImageBulk(arrSKU);
  };

  useEffect(() => {
    if (deliveries?.order_per_seller.length) {
      handleGetSKU();
    }
  }, [orderDetail]);

  const handleStatusToText = (val) => {
    const statusString = dataStatus.find((f) => f.id === val);
    return statusString || {};
  };

  const handleCopy = (val) => {
    if (val) {
      navigator.clipboard.writeText(val);
      message.success("Berhasil disalin");
    } else {
      message.error("Gagal disalin, No Resi tidak ditemukan!");
    }
  };

  const handleLacak = (value) => {
    const trackHref = `/${storeName}/profile/list-transaction/${orderNumber}/track`;
    navigate(trackHref, {
      state: {
        trackList: value,
      },
    });
  };

  const totalTransaksi = totalAmountFinal + totalMarginSOPromo;

  const diskonBiayaKirim =
    totalAmount - totalDiscount + totalDeliveryFeeFinal - totalTransaksi;

  return (
    <LayoutApp hideBottomNavbar>
      {loadingOrderDetail && <Loading />}
      <div className="detail-transaction pt-5 bg-light">
        <CustomBackHeader title="Rincian Pesanan" />
        <div className="infobox bg-white p-4 mb-2">
          <p className="mb-2 d-flex align-items-center justify-content-between">
            {orderNumber}
            {orderStatus > 11 && orderStatus < 16 ? (
              <Link
                className="text-blue text-bold"
                to={`/${storeName}/profile/list-transaction/${orderNumber}/invoice`}
              >
                Lihat Invoice
              </Link>
            ) : (
              <div className="text-gray text-bold">Lihat Invoice</div>
            )}
          </p>
          <p className="mb-2 d-flex align-items-center justify-content-between">
            Tanggal Pembelian{" "}
            <span>{moment(createDate).format("DD MMM YYYY, HH:mm")}</span>
          </p>
          <p className="mb-0 d-flex align-items-center justify-content-between">
            Status Transaksi{" "}
            <b className="text-red">{handleStatusToText(orderStatus).label}</b>
          </p>
        </div>

        {deliveries?.order_per_seller.map((res, i) => {
          const totalItem = res?.deliveryItems?.items
            .map((q) => q?.qtyOrdered)
            ?.reduce((a, b) => a + b, 0);

          return (
            <div className="listbox p-4 bg-white mb-2" key={i}>
              <p className="mb-1 text-bold">
                Supplier #{i + 1} (
                {JSON.stringify(res?.deliveryItems?.items?.length)} Barang)
              </p>
              <p className="mb-3 text-gray weight-light">Kota Jakarta Timur</p>
              {res?.deliveryItems?.items.map((val, j) => (
                <div className="box d-flex mb-4 pb-3 border-bottom" key={j}>
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      CheckoutImages.find((f) => f.plu === val?.skuSeller)
                        ?.image
                    }
                    alt=""
                    className="thumb me-2"
                  />
                  <div>
                    <p className="mb-1 text-bold">{val?.name}</p>
                    <p className="mb-0 qty">{val?.qtyOrdered} Barang</p>
                    {/* <p className="weight-light mb-0">
                      {rupiahFormat(val?.amountFinal)}
                    </p> */}
                    {val?.discount ? (
                      <div className="">
                        <div className="mb-0 d-flex align-items-center">
                          <s
                            style={{
                              fontSize: 14,
                              color: "#BABABA",
                              fontWeight: "600",
                            }}
                          >
                            {rupiahFormat(val?.defaultPrice)}
                          </s>{" "}
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              height: 19,
                              background: "#FDD203",
                              color: "#CD3438",
                              fontSize: 10,
                              borderRadius: 4,
                              padding: "0 4px",
                              marginLeft: 4,
                              fontWeight: 600,
                            }}
                          >
                            -
                            {Math.round(
                              ((val?.defaultPrice -
                                (val?.defaultPrice - val?.discount)) /
                                val?.defaultPrice) *
                                100,
                            )}
                            %
                          </div>
                        </div>
                        <div className="price">
                          {rupiahFormat(val?.defaultPrice - val?.discount)}
                        </div>
                      </div>
                    ) : (
                      <div className="price">
                        {rupiahFormat(val?.defaultPrice)}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <p className="mb-1 text-bold">Alamat Pengiriman</p>
              <p className="mb-1 weight-light">
                {res?.deliveryAddress?.intendedReceiver}
              </p>
              <p className="text-gray addresstext mb-3">
                {res?.deliveryAddress?.phone} <br />
                {res?.deliveryAddress?.address}{" "}
                {res?.deliveryAddress?.kecamatan}{" "}
                {res?.deliveryAddress?.kelurahan}
                {res?.deliveryAddress?.city} {res?.deliveryAddress?.kelurahan}
              </p>
              <p className="mb-1 text-bold">Kurir</p>
              <p className="mb-3">
                {res?.deliveryCompany} ({res?.deliveryAddress?.estimated})
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0 d-flex text-bold">
                  No Resi
                  <img
                    src={copyImage}
                    alt="copy"
                    className="pointer ms-2"
                    onClick={() => handleCopy(res?.noAwb)}
                  />
                </p>
                {/* <div
                  className="text-red text-bold pointer"
                  onClick={() => handleLacak(res?.deliveriesTrackingBean)}>
                  Lacak
                </div> */}
              </div>
              <p className="mb-3">{res?.noAwb || "-"}</p>
              <p className="text-bold mb-1">No. Shipment</p>
              <p className="mb-3">{res?.deliveryNo}</p>
              <p className="text-bold mb-1">Status Pengiriman</p>
              <p className="pb-4 border-bottom text-red mb-0 ">
                {res?.deliveryStatus || "-"}
              </p>

              <div className="pt-4">
                <p className="mb-1 d-flex align-items-center justify-content-between">
                  Subtotal ({totalItem} Barang){" "}
                  <span>{rupiahFormat(res?.subTotal)}</span>
                </p>
                <p className="mb-1 d-flex align-items-center justify-content-between">
                  Diskon
                  <span>- {rupiahFormat(res?.subTotalDiscount)}</span>
                </p>
                <p className="mb-1 d-flex align-items-center justify-content-between">
                  Biaya Kirim <span>{rupiahFormat(res?.deliveryFee)}</span>
                </p>
                {/* <p className="mb-1 d-flex align-items-center justify-content-between">
                  Diskon Biaya Kirim
                  <span> - {rupiahFormat(res?.deliveryFeeDiscount)}</span>
                </p> */}
                <p className="mb-1 d-flex align-items-center justify-content-between">
                  Total Biaya Kirim{" "}
                  <span>{rupiahFormat(res?.deliveryFeeFinal)}</span>
                </p>
                {/* <p className="mb-1 d-flex align-items-center justify-content-between">
                  Asuransi Pengiriman <span>Rp 2.500</span>
                </p> */}
                <p className="mb-0 d-flex align-items-center justify-content-between">
                  <b>Total</b>{" "}
                  {rupiahFormat(
                    res?.subTotalAmountFinal +
                      res?.subTotalDirectMargin +
                      res?.deliveryFeeFinal,
                  )}
                </p>
              </div>
            </div>
          );
        })}

        <div className="p-4 bg-white">
          <p className="mb-1 text-bold">Rincian Pembayaran </p>
          <p className="mb-3 d-flex align-items-center justify-content-between">
            Metode Pembayaran <span>{paymentDescriptionIn || "-"}</span>
          </p>

          <p className="mb-1 d-flex align-items-center justify-content-between">
            Subtotal <span>{rupiahFormat(totalAmount)}</span>
          </p>
          <p className="mb-1 d-flex align-items-center justify-content-between">
            Diskon <span>- {rupiahFormat(totalDiscount)}</span>
          </p>
          <p className="mb-1 d-flex align-items-center justify-content-between">
            Biaya Kirim <span>{rupiahFormat(totalDeliveryFeeFinal)}</span>
          </p>
          <p className="mb-1 d-flex align-items-center justify-content-between">
            Diskon Biaya Kirim <span>- {rupiahFormat(diskonBiayaKirim)}</span>
          </p>
          {/* <p className="mb-1 d-flex align-items-center justify-content-between">
            Asuransi Pengiriman <span>Rp 2.500</span>
          </p> */}
          {/* <p className="mb-0 pb-2 border-bottom d-flex align-items-center justify-content-between">
            Biaya Layanan <span>Rp 500</span>
          </p> */}
          <p className="my-2  d-flex align-items-center justify-content-between">
            Total{" "}
            <span>{rupiahFormat(totalAmountFinal + totalMarginSOPromo)}</span>
          </p>
          {/* <p className="mb-2 text-success text-bold d-flex align-items-center justify-content-between">
            Margin (Potongan Langsung) <span>- Rp 12.400</span>
          </p> */}
          <p className="my-4 text-bold d-flex align-items-center justify-content-between">
            Total yang dibayar{" "}
            <span> {rupiahFormat(totalAmountFinal + totalMarginSOPromo)}</span>
          </p>
        </div>
        {(orderStatus === 11 || orderStatus === 10) && (
          <div
            className="fixed-bottom px-4"
            style={{ height: 100, paddingTop: 30 }}
          >
            <CustomButton
              onClick={() =>
                navigate(`/${storeName}/cart/payment/${orderNumber}`)
              }
              title="Informasi Pembayaran"
              className="w-100"
              width="100%"
            />
          </div>
        )}
      </div>
    </LayoutApp>
  );
}
