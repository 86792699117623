import React, { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";

import { rupiahFormat } from "../../../app/helper";
import radioOff from "../../../img/payment/radioOff.svg";
import radioOn from "../../../img/payment/radioOn.svg";
import CustomButton from "../../shared/components/CustomButton";
import CustomBackHeader from "../../shared/components/CustomBackHeader";
import { useSelector } from "react-redux";
import CustomDrawer from "../../shared/components/CustomDrawer";
import Loading from "../../shared/components/Loading";

export default function ListBankSection({
  bankData,
  handleChoosedBank,
  handleActiveMenu,
  IsActiveMenu,
  ChoosedBank,
  loading,
  LoadingBank,
  handlePayment,
}) {
  const navigate = useNavigate();
  const { storeName, orderNumber } = useParams();

  const { orderDetail } = useSelector((state) => state.cart);

  const [OpenDrawerPayment, setOpenDrawerPayment] = useState(false);
  const handleDrawerPayment = () => setOpenDrawerPayment((p) => !p);

  // eslint-disable-next-line no-unused-vars
  const goToPrivacyPolicy = () => {
    const url = encodeURIComponent("KEBIJAKAN PRIVASI PT SUMBER ALFARIA TRIJAYA TBK (17072024)");
    navigate(`/${storeName}/cart/payment/${orderNumber}/${url}`);
  };

  const totalTransaksi =
    orderDetail?.totalAmountFinal + orderDetail?.totalMarginSOPromo;

  const diskonBiayaKirim =
    orderDetail?.totalAmount -
    orderDetail?.totalDiscount +
    orderDetail?.totalDeliveryFeeFinal -
    totalTransaksi;

  return (
    <div>
      <CustomBackHeader title="Pembayaran" href={`/${storeName}`} />
      {LoadingBank && <Loading />}
      <div className="p-4 mt-5 ">
        <p className="mb-4 text-bold mt-3" style={{ fontSize: 16 }}>
          Pilih Metode Pembayaran
        </p>
        {/* <pre>{JSON.stringify(bankData, 0, 2)}</pre> */}
        {bankData?.map((res, i) => (
          <div
            className=""
            key={i}
            style={{
              display: res?.groupList?.length === 0 ? "none" : "block",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center py-3 pointer"
              onClick={() => handleActiveMenu(i)}
            >
              <div className="d-flex align-items-center">
                <img src={res?.icon} alt="type-payment" className="me-2" />
                <p className="mb-0 text-bold">{res?.groupName}</p>
              </div>
              {IsActiveMenu === i ? (
                <HiChevronUp size={22} />
              ) : (
                <HiChevronDown size={22} />
              )}
            </div>
            {IsActiveMenu === i &&
              res?.groupList?.map((v, i) => (
                <div
                  className="d-flex justify-content-between align-items-center py-3"
                  key={i}
                >
                  <div
                    className="d-flex align-items-center pointer"
                    onClick={() => handleChoosedBank(v)}
                  >
                    <img
                      src={v?.data?.image.replaceAll('"', "")}
                      alt=""
                      className="me-2 p-1"
                      style={{
                        aspectRatio: "2/1",
                        objectFit: "contain",
                        width: 60,
                        border: "0.75px solid #EDEDED",
                        borderRadius: 3,
                      }}
                    />

                    <p className="mb-0 text-bold">{v?.data?.bankName}</p>
                  </div>
                  <span>
                    {v?.id === ChoosedBank?.id ? (
                      <img
                        src={radioOn}
                        alt="radio-on"
                        //   onClick={() => handleChoosedBank(v)}
                      />
                    ) : (
                      <img
                        src={radioOff}
                        className="pointer"
                        alt="radio-off"
                        onClick={() => handleChoosedBank(v)}
                      />
                    )}
                  </span>
                </div>
              ))}
          </div>
        ))}
      </div>
      <div className="p-4 fixed-bottom bg-white">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="text-bold mb-0">Total Pesanan</p>
            <p
              className="text-bold mb-0 text-red pointer d-flex align-items-center"
              onClick={handleDrawerPayment}
              style={{ fontSize: 16 }}
            >
              {rupiahFormat(totalTransaksi)}{" "}
              {!OpenDrawerPayment ? (
                <HiChevronUp className="text-dark ms-2" size={18} />
              ) : (
                <HiChevronDown className="text-dark ms-2" size={18} />
              )}
            </p>
          </div>

          <CustomButton
            onClick={handlePayment}
            width={160}
            title="Bayar"
            loading={loading}
            disabled={ChoosedBank === null}
          />
        </div>
        <div className="pt-4 text-gray">
          <p>
            Dengan melanjutkan pembayaran, Anda tunduk dan setuju terhadap{' '}
            serta{" "}
            <span className="text-red pointer" onClick={goToPrivacyPolicy}>
              Kebijakan Privasi
            </span>{" "}
            yang berlaku
          </p>
        </div>
      </div>
      <CustomDrawer
        open={OpenDrawerPayment}
        onClose={handleDrawerPayment}
        className="drawer-address-menu"
      >
        <div className="pt-5 pb-4 px-3">
          <p
            className="pb-4 border-bottom text-bold text-red"
            style={{ fontSize: 16 }}
          >
            Ringkasan Pembayaran
          </p>
          <p className="mb-2 d-flex align-items-center justify-content-between text-bold">
            Nomor Order{" "}
            <span className="weight-light">{orderDetail.orderNumber}</span>
          </p>

          <p className="mb-2 d-flex align-items-center justify-content-between text-bold">
            Transaksi
            {/* (5 Barang){" "} */}
            <span className="weight-light">{rupiahFormat(totalTransaksi)}</span>
          </p>
          <p className="mb-2 d-flex align-items-center justify-content-between text-bold">
            Subtotal
            <span className="weight-light">
              {rupiahFormat(orderDetail?.totalAmount)}
            </span>
          </p>
          <p className="mb-2 d-flex align-items-center justify-content-between text-bold">
            Diskon{" "}
            <span className="weight-light">
              - {rupiahFormat(orderDetail?.totalDiscount)}
            </span>
          </p>

          {/* <p className="mb-2 d-flex align-items-center justify-content-between text-bold">
            Margin (Potongan langsung){" "}
            <span className="weight-light">
              - {rupiahFormat(orderDetail?.totalMarginSOPromo)}
            </span>
          </p> */}
          <p className="mb-2 d-flex align-items-center justify-content-between text-bold">
            Biaya Kirim{" "}
            <span className="weight-light">
              {rupiahFormat(orderDetail?.totalDeliveryFeeFinal)}
            </span>
          </p>
          <p className="mb-2 d-flex align-items-center justify-content-between text-bold">
            Total diskon Biaya Kirim{" "}
            <span className="weight-light">
              - {rupiahFormat(diskonBiayaKirim)}
            </span>
          </p>
          {/* <p className="mb-4 d-flex align-items-center justify-content-between text-bold">
            Biaya Layanan{" "}
            <span className="weight-light">{rupiahFormat(0)}</span>
          </p> */}
        </div>
      </CustomDrawer>
    </div>
  );
}
