import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Masonry from "react-responsive-masonry";

import bgRecommendation from "../../../img/bg-rekomendasi-jualan.png";
import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import CardProductComponent from "../../../components/CardProductComponent";
import {
  getCategory,
  getProductDetail,
  getRecommendationList,
} from "../store.api";
import { handleDrawerProductData } from "../store.reducer";
import DetailDrawer from "./DetailDrawer";

export default function ProductRecommendationList() {
  const { storeName } = useParams();
  const dispatch = useDispatch();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { listRecommendation, loading, contentRecommendation } = useSelector(
    (state) => state.store,
  );

  const handleOpenDrawer = (val) => {
    setIsOpenDrawer(true);
    if (val.is_group) {
      const params = {
        plu: val.plu,
      };
      dispatch(getProductDetail(params))
        .then(unwrapResult)
        .then((res) => {
          dispatch(handleDrawerProductData(res.message));
        })
        .catch((err) => console.log(err));
    } else {
      dispatch(handleDrawerProductData(val));
    }
  };

  useEffect(() => {
    dispatch(getRecommendationList());
  }, []);

  const onClose = () => {
    setIsOpenDrawer((prev) => !prev);
    dispatch(handleDrawerProductData({}));
  };

  return (
    <div className="mt-4">
      <img
        src={bgRecommendation}
        className="rec-header-img"
        onError={(event) =>
          (event.target.src =
            "https://nayemdevs.com/wp-content/uploads/2020/03/default-product-image.png")
        }
        alt="Product Recommendation"
      />
      <div className="rec-header-text px-4">
        <h3>Spesial Promo Untuk Kamu</h3>
      </div>
      <div className="productlist px-4 pb-4">
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <Spin tip="Loading..." />
          </div>
        ) : (
          <Masonry columnsCount={2} gutter="15px">
            {listRecommendation.map((product, i) => (
              <CardProductComponent
                key={product.name}
                product={product}
                onClick={handleOpenDrawer}
              />
            ))}
          </Masonry>
        )}
        <DetailDrawer open={isOpenDrawer} onClose={onClose} />
      </div>
    </div>
  );
}
