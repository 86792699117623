import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Steps } from "antd";
import moment from "moment";

import LayoutApp from "../../shared/components/LayoutApp";
import CustomBackHeader from "../../shared/components/CustomBackHeader";

import dataStatus from "../../../app/dataStatus";
import { useEffect } from "react";

export default function TrackingOrderContainer() {
  const { trackList } = useLocation().state;

  const [TrackListData, setTrackListData] = useState([]);

  useEffect(() => {
    const sortedTrack = trackList.sort(
      (a, b) => new Date(b.deliveryDate) - new Date(a.deliveryDate)
    );
    const remap = sortedTrack.map((res, i) => ({
      status: i === 0 ? "process" : "wait",
      //   subTitle: "dfsdf",
      title: dataStatus?.find((f) => f.id === res.deliveryStatus).label,
      description: moment(res.deliveryDate).format("DD-MM-YYYY, HH:mm"),
    }));
    setTrackListData(remap);
  }, [trackList]);

  return (
    <LayoutApp hideBottomNavbar>
      <div className="detail-transaction pt-5 bg-light">
        <CustomBackHeader title="Lacak Pesanan" />
        <div className="pt-4 px-4 bg-white mt-4">
          <Steps
            direction="vertical"
            // size="large"
            progressDot
            current={1}
            items={TrackListData}
          />
        </div>
        {/* {JSON.stringify(trackList)} */}
      </div>
    </LayoutApp>
  );
}
