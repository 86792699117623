import React from "react";
import NavbarBottom from "./NavbarBottom";

export default function LayoutApp({ children, hideBottomNavbar }) {
  return (
    <div className="layout">
      <main className="content">{children}</main>
      {!hideBottomNavbar && <NavbarBottom />}
    </div>
  );
}
