import { Button, Input } from "antd";
import React from "react";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sendWhatsAppMe } from "../../../app/helper";
import bgHeader from "../../../img/bgHeader2.svg";
import chatTokoIcon from "../../../img/chatTokoIcon.svg";
import searchIcon from "../../../img/searchIcon.svg";
import { getStore } from "../store.api";
import { handleSearchText } from "../store.reducer";

export default function HeaderStore() {
  const dispatch = useDispatch();
  const { storeName } = useParams();
  const { activeCategory, searchText } = useSelector((state) => state.store);

  // useEffect(() => {
  //   // dispatch(handleActiveCategory("semua"));
  // }, []);
  const handleChatStore = () => {
    const textWA = `?text=Halo%2C%20saya%20mau%20tanya%20produk%20di%20toko%20kamu%3A%0A${window.location.href}`;
    sendWhatsAppMe(localStorage.getItem("alfamindStorePhone"), textWA);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      const data = {
        filterValue: activeCategory,
        store: storeName,
        params: {
          search: searchText,
        },
      };
      dispatch(getStore(data));
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, activeCategory]);

  useEffect(() => {
    return () => dispatch(handleSearchText(""));
  }, []);

  return (
    <section
      className="headerstore py-3 fixed-header"
      style={{ backgroundImage: `url(${bgHeader})` }}
    >
      <div className="px-4">
        <Input
          style={{ height: 32, borderRadius: "100px", fontSize: 12 }}
          placeholder="Cari Produk di Toko ini"
          prefix={<img className="mx-1" src={searchIcon} alt="search icon" />}
          className="mb-4"
          value={searchText}
          allowClear
          onChange={(e) => dispatch(handleSearchText(e.target.value))}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between pb-4 px-4 wraptitle">
        <div className="title">{localStorage.getItem("alfamindStoreName")}</div>
        <Button className="cusbtn" type="primary" onClick={handleChatStore}>
          <img src={chatTokoIcon} alt="" />
        </Button>
      </div>
    </section>
  );
}
